import React from "react";
// Customizable Area Start
import {
  ScrollView,
  Image,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  TextInput
} from "react-native";

import scale, { verticalScale } from "../../../components/src/Scale";

//@ts-ignore
// import { Dropdown } from "react-native-material-dropdown";
import PostCreationController from "./PostCreationCommonController";
import {
  backImg,
  zoomInImg,
  zoomOutImg,
  alubumImg,
  copyImg
} from "../src/assets";
import Scale from "../../../components/src/Scale";
import Select from "react-select";
import {
  Box,
  Button,
  Modal,
  Typography,
  styled
} from '@material-ui/core'
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          <div>
            <Button onClick={this.handleOpen}></Button>
            <Modal
              open={this.state.open}
            onClose={this.handleClose}
            >
              <ModalBox>
                <ModalMainBox>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <CropBox>
                      <img src={backImg} />
                      <Typography className='cropTextStyle'></Typography>
                      <Typography className='nextTextStyle'></Typography>
                    </CropBox>
                    <ImageBox>
                      <img src={alubumImg} />
                    </ImageBox>
                    <ZoomContainer>
                      <Box className='zoomOutInBox'>
                        <Box className='zoomOut'>
                          <img src={zoomInImg} />
                        </Box>
                        <Box className='zoomOut'>
                          <img src={zoomOutImg} />
                        </Box>
            </Box>
                      <Box className='zoomOut'>
                        <img src={copyImg} />
                      </Box>
                    </ZoomContainer>
                  </Typography>
                </ModalMainBox>
              </ModalBox>
            </Modal>
            </div>
        </ScrollView>
      </SafeAreaView>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "90%"
  },
  inputTextField: {
    height: verticalScale(80),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    margin: scale(3),
    padding: 10,
    marginTop: verticalScale(5)
  },

  inputTextField1: {
    height: verticalScale(50),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    paddingLeft: 10,
    paddingRight: 10,
    margin: scale(3)
  },

  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 22,
    backgroundColor: "#366ef9",
    height: scale(50),
    marginTop: verticalScale(20)
  },
  imageStyle: {
    width: scale(100),
    height: scale(100),
    borderRadius: scale(50),
    borderWidth: 1,
    borderColor: "black",
    marginTop: verticalScale(5),
    marginBottom: verticalScale(25),
    opacity: 0.6
  },
  buttonText: {
    color: "white"
  },
  editIcon: {
    position: "absolute",
    height: scale(20),
    width: scale(20),
    resizeMode: "contain",
    top: verticalScale(90),
    left: Scale(90)
  }
});

const ModalBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margimg: "10px"
});

const ModalMainBox = styled(Box)({
  width: "620px",
  background: "#D9D9D9",
  height: "662px",
  boxShadow: "24",
  borderRadius: "15px"
});

const CropBox = styled(Box)({
  height: "43px",
  flexShrink: 0,
  background: "#262626",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 10px",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",

  "& .cropTextStyle": {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    letterSpacing: "-0.36px"
  },

  "& .nextTextStyle": {
    color: "#0095F6",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140 %",
    letterSpacing: "-0.28px"
  }
});

const ImageBox = styled(Box)({
  width: "620px",
  height: "560px",
  flexShrink: 0,
  background: "#D9D9D9",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const ZoomContainer = styled(Box)({
  height: "43px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 10px",
  marginBottom: "20px",
  "& .zoomOut": {
    height: "40px",
    width: "40px",
    borderRadius: "20px",
    background: "#2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  "& .zoomOutInBox": {
    display: "flex",
    alignItems: "center",
    width: "100px",
    justifyContent: "space-between"
  }
});
// Customizable Area End
