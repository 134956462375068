import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  styled,
  Grid,
  Modal,
  TextareaAutosize
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  userProfile,
  quilaLogo,
  savedIcon,
  settingsIcon,
  supportsIcon,
  appointmentIcon,
  notificationIcon,
  exploreIcon,
  moodIcon,
  msgIcon,
  porifleIcon,
  createPostIcon,
  logoutIcon,
  profileImg,
  backIcon,
  zoomInIcon,
  copyIcon,
  albumImg,
  postImg,
  smileImg,
  mapIcon,
  downIcon,
  backArrow,
  rightArrow,
  cutomerIcon,
  locationIcon,
  categoryIcon
} from "./assets";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  list = () => (
    <div
      className="left"
      style={{
        width: 250,
      }}
      role="presentation"
      onClick={this.toggleDrawer}
      onKeyDown={this.toggleDrawer}
    >
      <Box style={webStyle.userProfileWrapper}>
        <img src={userProfile} style={webStyle.userProfile} />
        <Typography variant="h6">{configJSON.userName}</Typography>
        <Typography variant="h6" style={webStyle.userDesignation}>
          {configJSON.userDesignation}
        </Typography>
        <Button
          onClick={this.toggleDrawer}
          variant="text"
          style={webStyle.logout}
        >
          {configJSON.logout}
        </Button>
      </Box>
      <Divider />
      <List>
        {this.state.drawerItems?.length > 0 &&
          this.state.drawerItems?.map((item: any) => {
            let data = item?.data?.attributes;

            if (data?.position !== "left") {
              return null;
            }

            return (
              <React.Fragment key={data?.id}>
                {data?.items?.map((mItem: any) => {
                  return (
                    <ListItem
                      button
                      key={mItem?.id?.toString()}
                      onClick={() => this.onPressMenuItem(mItem)}
                    >
                      <ListItemIcon>
                        <img src={item.icon} style={webStyle.drawerItemIcon} />
                      </ListItemIcon>
                      <ListItemText primary={mItem?.name} />
                    </ListItem>
                  );
                })}
              </React.Fragment>
            );
          })}
      </List>
    </div>
  );

  renderModal = () => {
    return (
      <div>
        <Modal
          open={this.state.openModal}
          onClose={this.handleClose}
          style={webStyle.modalStyle}
        >
          <ModalBox>
            <ModalMainBox>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <CropBox>
                  <img src={backIcon} />
                  <Typography onClick={this.handleClose} className='cropTextStyle'>{configJSON.cropText}</Typography>
                  <Typography className='nextTextStyle'>{configJSON.nextText}</Typography>
                </CropBox>
                <ImageBox>
                  <img src={albumImg} />
                </ImageBox>
                <ZoomContainer>
                  <Box className='zoomOutInBox'>
                    <Box className='zoomOut'>
                      <img src={zoomInIcon} />
                    </Box>
                    <Box className='zoomOut'>
                      <img src={zoomInIcon} />
                    </Box>
                  </Box>
                  <Box className='zoomOut'>
                    <img src={copyIcon} />
                  </Box>
                </ZoomContainer>
              </Typography>
            </ModalMainBox>
          </ModalBox>
        </Modal>
      </div>
    )
  };

  renderModalSecond = () => {
    return (
      <div>
        <Modal
          open={this.state.shareModal}
          onClose={this.handleShareModalClose}
           style={webStyle.modalStyle}
        >
          <NewPostModal>
            <NewPost>
              <CreatePostBox>
                <img src={backIcon} />
                <Typography className='cropTextStyle'>{configJSON.creatpostText}</Typography>
                <Typography className='nextTextStyle'
                onClick={this.handleShareModalClose}
                >{configJSON.shareText}</Typography>
              </CreatePostBox>
              <ModalConatiner>
                <ImageContainer xs={8}>
                  <img src={postImg} className="postImageStyle" />
                </ImageContainer>
                <SideContainer xs={4}>
                  <Box className="profileBox">
                    <Box className="profileCircle" />
                    <Typography className="postTextStyle">{configJSON.postText}</Typography>
                  </Box>
                  <Box className="textInputBox">
                  <TextareaAutosize maxRows={10}
                    className="captionTextStyle"
                    aria-label="maximum height"
                    placeholder="Maximum 4 rows" defaultValue="Write a caption..." />
                    </Box>
                    <Box className="smileBox">
                       <img src={smileImg}/>
                       <Typography className="numberTestStyle">{configJSON.numberText}</Typography>
                    </Box>
                    <Box className="smileBox">
                       <Typography className="locationText">{configJSON.locationText}</Typography>
                       <img src={mapIcon}/>
                    </Box>
                    <Box className="smileBox">
                       <Typography className="locationText">{configJSON.accessibilityText}</Typography>
                       <img src={downIcon}/>
                    </Box>
                    <Box className="smileBox">
                       <Typography className="locationText">{configJSON.settingsText}</Typography>
                       <img src={downIcon}/>
                    </Box>
                </SideContainer>
              </ModalConatiner>
            </NewPost>
          </NewPostModal>
        </Modal>
      </div>
    )
  };

  renderSideBar = () => {
    return (
      <SidebarBox>
        <QualImage src={quilaLogo} alt="quila" />
        <Divider style={webStyle.dividerStyleTwo} />
        <Box className="boxContainer">
          <Box className="sideBarContainer">
            <Box>
              <List className="MuiList-padding">
                <ListItem >
                  <ListItemIcon className="MuiListItemIcon-root">
                    <img src={exploreIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Explore" />
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={notificationIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Notifications" />
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={msgIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Messages" />
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={appointmentIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Appointments" />
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={savedIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Saved" />
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={moodIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Mood" />
                </ListItem>
                <ListItem >
                  <ListItemIcon onClick={this.handleShareModalOpen}>
                    <img src={porifleIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItem>
                <ListItem onClick={this.handleOpen} >
                  <ListItemIcon>
                    <img src={createPostIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Create Post" />
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box>
            <List>
              <ListItem >
                <ListItemIcon>
                  <img src={supportsIcon} />
                </ListItemIcon>
                <ListItemText primary="Support" />
              </ListItem>
            </List >
            <List >
              <ListItem >
                <ListItemIcon>
                  <img src={settingsIcon} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </List>
            <Divider style={webStyle.dividerStyle}/>
            <Box style={webStyle.boxConatinerTwo}>
              <Box style={webStyle.usersImgStyle}>
                <img src={profileImg} />
              </Box>
              <Box style={webStyle.usersProdileBoxStyle}>
                <Typography style={webStyle.userNameTexts}>{configJSON.emailIdtext}</Typography>
                <Typography style={webStyle.userEmailText}>{configJSON.emailIdtextSecond}</Typography>
              </Box>
              <Box style={webStyle.logoutBtnStyle}>
                <img src={logoutIcon} />
              </Box>
            </Box>
          </Box>
        </Box>
      </SidebarBox>
    )
  };

  renderResponsiveView = () => {
    return (
      <ResponsiveContainer>
        <Box className="mainBoxStyle">
          <Box className="newPostView">
            <img src={backArrow} />
            <Typography className="newPostText">{configJSON.newPostText}</Typography>
            <Typography className="shareTestStyle">{configJSON.shareText}</Typography>
          </Box>
          <Box className="imageContainer">
            <img src={postImg} className="postImageStyles" />
          </Box>
          <Box className="titleInputComponent">
            <TextareaAutosize maxRows={10}
              className="titleInput"
              aria-label="maximum height"
              placeholder="Maximum 4 rows" defaultValue="Look title..." />
          </Box>
          <Divider />
          <Box className="descriptionBox">
            <TextareaAutosize maxRows={10}
              className="titleInput"
              aria-label="maximum height"
              placeholder="Maximum 4 rows" defaultValue="Write a description..." />
          </Box>
          <Divider />

          <Box className="customerView">
            <Box className="customerContainer">
              <img src={cutomerIcon} />
              <Typography className="callobaretText">{configJSON.collaboratetext}</Typography>
            </Box>
            <img src={rightArrow} />
          </Box>

          <Box className="customerView">
            <Box className="customerContainer">
              <img src={locationIcon} />
              <Typography className="callobaretText">{configJSON.locationText}</Typography>
            </Box>
            <img src={rightArrow} />
          </Box>

          <Box className="customerView">
            <Box className="customerContainer">
              <img src={categoryIcon} />
              <Typography className="callobaretText">{configJSON.categoryText}</Typography>
            </Box>
            <img src={rightArrow} />
          </Box>

          <Box className="customerView">
            <Box className="customerContainer">
              <img src={cutomerIcon} />
              <Typography className="callobaretText">{configJSON.styleText}</Typography>
            </Box>
            <img src={rightArrow} />
          </Box>

          <ShareButton
            type="submit"
            fullWidth
            variant="contained">
            {configJSON.sharebtnText}
          </ShareButton>
        </Box>
      </ResponsiveContainer>
    )
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      <MainContainer container>
        <Grid item sm={8} lg={3} md={3} xl={3}>
          {this.renderSideBar()}
        </Grid>
        <Grid item sm={8} lg={9} md={9} xl={9}>
          {this.renderModal()}
          {this.renderModalSecond()}
        </Grid>
      </MainContainer>
       {this.renderResponsiveView()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },

  dividerStyle:{
    margin:"15px"
  },

  dividerStyleTwo:{
    margin:"15px 0px"
  },

  boxConatinerTwo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width:"90%",
    margin:"10px"
  },

  usersImgStyle: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "200px"
  },

  logoutBtnStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around"
  },

  userNameTexts: {
    color: "#344054",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px"
  },

  userEmailText: {
    color: "#475467",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px"
  },

  usersProdileBoxStyle:{
    width:"70%"
  },

  modalStyle:{
    overflow: "scroll" 
  }
};

const QualImage = styled("img")({
  padding: "10px",
  width: "50%",
  xheight: "10x0%",
  objectFit: "cover",
  "@media (max-width:598px)": {
    flexDirection: "column",
    width: "33%"
  }
});

const SidebarBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "& .sideBarContainer": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  "& .boxContainer": {
    display: "flex",
    flexDirection: "column",
    height:"100%",
    justifyContent: "space-between",
    gap: "50%"
  },

  "& .MuiListItemIcon-root": {
    color: "rgba(0, 0, 0, 0.54)",
    display: "inline-flex",
    minWidth: "42px",
    flexShrink: 0
  },

  "& .MuiList-padding": {
    paddingBottom: "8px",
    margin: "0px 10px -12px"
  },

  "@media (max-width: 550px)": {
    display: 'none',
    height:"200px",
    width:"200px",
    background:"red"
  }
});

const ModalBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margimg: "10px",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const ModalMainBox = styled(Box)({
  width: "620px",
  background: "#D9D9D9",
  height: "662px",
  boxShadow: "24",
  borderRadius: "15px",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const CropBox = styled(Box)({
  flexShrink: 0,
  background: "#262626",
  alignItems: "center",
  padding: "0 10px",
  display: "flex",
  justifyContent: "space-between",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
  height: "43px",

  "& .cropTextStyle": {
    fontSize: "18px",
    fontStyle: "normal",
    color: "#FFF",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "140%",
    letterSpacing: "-0.36px"
  },

  "& .nextTextStyle": {
    color: "#0095F6",
    fontFamily: "Inter",
    lineHeight: "140 %",
    letterSpacing: "-0.28px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const ImageBox = styled(Box)({
  width: "620px",
  height: "560px",
  flexShrink: 0,
  background: "#D9D9D9",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const ZoomContainer = styled(Box)({
  height: "43px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 10px",
  marginBottom: "20px",
  "& .zoomOut": {
    height: "40px",
    width: "40px",
    borderRadius: "20px",
    background: "#2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  "& .zoomOutInBox": {
    display: "flex",
    alignItems: "center",
    width: "100px",
    justifyContent: "space-between"
  },
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const ModalConatiner = styled(Box)({
  display:"flex",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const NewPost = styled(Box)({
  width: "961px",
  height: "619px",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const NewPostModal = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margimg: "10px",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const CreatePostBox = styled(Box)({
  height: "43px",
  flexShrink: 0,
  background: "#262626",
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  padding: "0 10px",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",

  "& .cropTextStyle": {
    fontWeight: 700,
    lineHeight: "140%",
    letterSpacing: "-0.36px",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal"
  },

  "& .nextTextStyle": {
    color: "#0095F6",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "140 %",
    fontStyle: "normal",
    letterSpacing: "-0.28px"
  }
});

const ImageContainer = styled(Grid)({
  "& .postImageStyle":{
    width:"100%"
  },
  "@media (max-width: 600px)": {
    display:"none"
  }
})

const SideContainer = styled(Grid)({
  background:"#262626",
  "& .profileCircle":{
    width: "30px",
    height: "30px",
    backgroundColor: "#D9D9D9",
    borderRadius:"20px"
  },

  "& .postTextStyle":{
    color: "#FFFFFF",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.28px",
    marginLeft:"20px"
  },

  "& .textInputBox":{
    height:"150px"
  },

  "& .profileBox":{
    display:"flex",
    padding:"20px",
    alignItems:"center"
  },

  "& .captionTextStyle":{
    margin: "15px",
    height: "200px",
    overflow: "hidden",
    width: "295px",
    backgroundColor: "#262626",
    border: "none",
    color: "#737373",
    fontSsize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "-0.32px",
    resize:"none"
  },

  "& .smileBox":{
    display:"flex",
    margin:"20px",
    justifyContent:"space-between"
  },

  "& .numberTestStyle": {
    color: "#737373",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "-0.24px"
  },

  "& .locationText": {
    color: "#A8A8A8",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "-0.32px"
  },
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const MainContainer = styled(Grid)({
  "@media (max-width: 600px)": {
    display:"none"
  }
})

const ResponsiveContainer = styled(Box)({
   display:"none",
   "@media (max-width: 600px)": {
    display:"block"
   },

   "& .mainBoxStyle":{
      padding:"20px"
   },

   "& .newPostView":{
    height:"40px",
    witdh:"30%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
   }, 

  "& .newPostText": {
    color: "#010101",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: -"0.32px"
  },

  "& .shareTestStyle": {
    color: "#6551EA",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.32px"
  },

  "& .imageContainer":{
    height:"250px",
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },

  "& .postImageStyles":{
    height:"200px",
    width:"200px"
  },

  "& .titleInput": {
    border: "none",
    color: "#898989",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "-0.26px",
    resize:"none",
    width:"100%"
  },

  "& .titleInputComponent":{
    height:"50px"
  },

  "& .customerView":{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },

  "& .descriptionBox":{
    height:"60px",
    marginTop:"7%"
  },

  "& .customerContainer":{
    display:"flex",
    marginTop:"20px",
  },

  "& .callobaretText": {
    color: "#010101",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.28px",
    marginLeft:"10px"
  }  
});

const ShareButton = styled(Button)({
  marginTop: '3rem',
  textTransform: 'none',
  backgroundColor: '#0e1518',
  height: '45px',
  color: "#fff",
  fontSize: "15px",
  fontWeight: 600,
  marginBottom: "15px",
  "&:hover": {
    backgroundColor: '#0e1518'
  }
});
// Customizable Area End
