import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData} from "../../../framework/src/Utilities";

interface IOSSwitchProps {
  className?: string;
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}

interface UpdateGetNotificationResponse {
  comment: [
    {
      id: number,
      account_id: number,
      commentable_id: number,
      commentable_type: string,
      comment: string,
      created_at: string,
      updated_at: string,
      name: string,
      activated: true
    },
    {
      id: number,
      account_id: number,
      commentable_id: number,
      commentable_type: string,
      comment: string,
      created_at: string,
      updated_at: string,
      name: string,
      activated: true
    },
    {
      id: number,
      account_id: number,
      commentable_id: number,
      commentable_type: string,
      comment: string,
      created_at: string,
      updated_at: string,
      name: string,
      activated: true
    }
  ],
  tag: [
    {
      id: number,
      name: string,
      created_at: string,
      updated_at: string,
      activated: true,
      account_id: number
    },
    {
      id: number,
      name: string,
      created_at: string,
      updated_at: string,
      activated: true,
      account_id: number
    },
    {
      id: number,
      name: string,
      created_at: string,
      updated_at: string,
      activated: false,
      account_id: number
    }
  ],
  reminder: [
    {
      id: number,
      name: string,
      activated: false,
      account_id: number,
      created_at: string,
      updated_at: string
    },
    {
      id: number,
      name: string,
      activated: false,
      account_id: number,
      created_at: string,
      updated_at: string
    },
    {
      id: number,
      name: string,
      activated: false,
      account_id: number,
      created_at: string,
      updated_at: string
    }
  ],
  other_activity: [
    {
      id: number,
      name: string,
      activated: false,
      account_id: number,
      created_at: string,
      updated_at: string
    },
    {
      id: number,
      name: string,
      activated: false,
      account_id: number,
      created_at: string,
      updated_at: string
    },
    {
      id: number,
      name: string,
      activated: false,
      account_id: number,
      created_at: string,
      updated_at: string
    }
  ],
  message: string
}
interface PutnotificationResponse {
  errors: [
    {
      message: string
    }
  ]
}

interface SettingSuccessResponse {
  data: [
    {
      id: number,
      type: string,
      attributes: {
        name: string,
        title: null,
        activated: boolean
      }
    },
    {
      id: number,
      type: string,
      attributes: {
        name: string,
        title: null,
        activated: boolean
      }
    },
    {
      id: number,
      type: string,
      attributes: {
        name: string,
        title: null,
        activated: boolean
      }
    },
    {
      id: number,
      type: string,
      attributes: {
        name: string,
        title: null,
        activated: boolean
      }
    }
  ]
}

interface ResponseDataSettings {
  settings: [
    {
      id: number,
      title: null,
      name: string,
      account_id: number,
      activated: boolean
    },
    {
      id: number,
      title: null,
      name: string,
      account_id: number,
      activated: boolean
    },
    {
      id: number,
      title: null,
      name: string,
      account_id: number,
      activated: boolean
    },
    {
      id: number,
      title: null,
      name: string,
      account_id: number,
      activated: boolean
    }
  ],
  privacy_settings: [
    {
      id: number,
      account_id: number,
      consent_to: null,
      consent_for: string,
      access_level: null,
      status: null,
      created_by: null,
      updated_by: null,
      is_active: boolean,
      created_at: string,
      updated_at: string,
      name: string
    },
    {
      id: number,
      account_id: number,
      consent_to: null,
      consent_for: string,
      access_level: null,
      status: null,
      created_by: null,
      updated_by: null,
      is_active: boolean,
      created_at: string,
      updated_at: string,
      name: string
    },
    {
      id: number,
      account_id: number,
      consent_to: null,
      consent_for: string,
      access_level: null,
      status: null,
      created_by: null,
      updated_by: null,
      is_active: boolean,
      created_at: string,
      updated_at: string,
      name: string
    }
  ],
  message: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  togglePush: boolean,
  value: number;
  toggles: boolean
  // Customizable Area Start
  token:string;
  push_notification:boolean;
  pause_like:boolean;
  pause_booking:boolean;
  comment_review:boolean;
  hide_acount:boolean;
  hide_telent_account:boolean;
  show_address_only_for_approval:boolean;
  settingsUpdateResponse:Object;
  settingResponse:Object;
  comment_push:boolean;
  comment_email:boolean;
  comment_sms:boolean;
  tags_push:boolean;
  tags_email:boolean;
  tags_sms:boolean;
  reminder_push:boolean;
  reminder_email:boolean;
  reminder_sms:boolean;
  activity_push:boolean;
  activity_email:boolean;
  activity_sms:boolean;
  notificationResponse:Object;
  fullName:string;
  emailID:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  patchSettingApiCallID:string = ""; 
  getSettingsApiCallID:string = "";
  putNotificationApiCallID:string = "";
  getNotificationApiCallID:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.sendEmailIdProps),
      getName(MessageEnum.sendNameIdProps)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      togglePush: false,
      value: 0,
      toggles:false,
      // Customizable Area Start
      token:"",
      push_notification: false,
      pause_like: false,
      pause_booking: false,
      comment_review: false,
      hide_acount:false,
      hide_telent_account:false,
      show_address_only_for_approval:false,
      settingsUpdateResponse:{},
      settingResponse:{},
      comment_push: false,
      comment_email: false,
      comment_sms: false,
      tags_push: false,
      tags_email: false,
      tags_sms: false,
      reminder_push: false,
      reminder_email: false,
      reminder_sms: false,
      activity_push: false,
      activity_email: false,
      activity_sms: false,
      notificationResponse:{},
      fullName:"",
      emailID:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleApiResponse(message)
    // Customizable Area End
  }
 
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleChange = (event: unknown, newValue: number) => {
    this.setState({ value: newValue });
  };

  a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }
  
  handleChangeToogle = () =>{
    this.setState({togglePush:!this.state.togglePush})
  };

  handleChangePushNotification = () => {
    this.setState({
      push_notification: !this.state.push_notification
    },
      () => this.patchSettingUpdateFunction());
  };

  handleChangePauseLike = () => {
    this.setState({
      pause_like: !this.state.pause_like
    },
      () => this.patchSettingUpdateFunction());
  };

  handlePauseBooking = () => {
    this.setState({
      pause_booking: !this.state.pause_booking
    },
      () => this.patchSettingUpdateFunction());
  };

  handleCommentReview = () => {
    this.setState({
      comment_review: !this.state.comment_review
    },
      () => this.patchSettingUpdateFunction());
  };

  handlehideAcount = () => {
    this.setState({
      hide_acount: !this.state.hide_acount
    },
      () => this.patchSettingUpdateFunction());
  };

  handlehideTelentAcount = () => {
    this.setState({
      hide_telent_account: !this.state.hide_telent_account
    },
      () => this.patchSettingUpdateFunction());
  };

  handleShowAddress = ()=>{
    this.setState({
      show_address_only_for_approval: !this.state.show_address_only_for_approval},
      ()=> this.patchSettingUpdateFunction());
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
  
    const header = {
      "Content-Type": contentType,
       token : await getStorageData("userRole")
    };
    const userrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    userrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    userrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    userrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      userrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(userrequestMessage.id, userrequestMessage);
    return userrequestMessage.messageId;
  };

  handleApiResponse(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let emailId = message.getData(getName(MessageEnum.sendEmailIdProps))
      let nameId = message.getData(getName(MessageEnum.sendNameIdProps));
      this.setState({emailID:emailId, fullName: nameId})
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSettingUpdateSuccessCall(apiRequestCallId, responseJson)
        this.responseGetSettingSuccessCall(apiRequestCallId,responseJson)
        this.responseNotificationUpdateSuccessCall(apiRequestCallId,responseJson)
        this.responseGetNotificationSuccessCall(apiRequestCallId,responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseSettingUpdateFailureCall(apiRequestCallId, responseJson)
        this.responseGetUpdateFailureCall(apiRequestCallId,responseJson)
        this.responseNotificationUpdateFailureCall(apiRequestCallId,responseJson)
        this.responseGetNotificationFailureCall(apiRequestCallId,responseJson)
      } 
    }
  }

  patchSettingUpdateFunction = async () => {
    let apiData = {
      settings: [
        {
          "name": "push_notification",
          "activated": this.state.push_notification
        },
        {
          "name": "pause_like",
          "activated": this.state.pause_like
        },
        {
          "name": "pause_booking",
          "activated": this.state.pause_booking
        },
        {
          "name": "comment_review",
          "activated": this.state.comment_review
        }
      ],
      privacy_settings: [
        {
          "name": "hide_acount",
          "is_active": this.state.hide_acount
        },
        {
          "name": "hide_telent_account",
          "is_active": this.state.hide_telent_account
        },
        {
          "name": "show_address_only_for_approval",
          "is_active": this.state.show_address_only_for_approval
        }
      ]
    }
  
    this.patchSettingApiCallID = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.settingApiMethod,
      endPoint: configJSON.settingApiEndPoint,
      body: apiData
    });
  };

  responseSettingUpdateSuccessCall = async (apiRequestCallId: string, responseJson: SettingSuccessResponse) => {
    if (apiRequestCallId === this.patchSettingApiCallID) {
      this.settingUpdateSuccessCallBack(responseJson);
    }
  };

  responseSettingUpdateFailureCall = async (apiRequestCallId: string, errorReponse: SettingSuccessResponse) => {
    if (apiRequestCallId === this.patchSettingApiCallID) {
      this.settingUpdateFailureCallBack(errorReponse);
    }
  };

  settingUpdateSuccessCallBack =(responseJson: SettingSuccessResponse) => {
    this.setState({settingsUpdateResponse:responseJson})
  };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              

  settingUpdateFailureCallBack = (responseJson: SettingSuccessResponse) => {
    this.setState({settingsUpdateResponse:responseJson})
  };

  getSettingApiFunction = async () => {
    this.getSettingsApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.settingGetApiEndPoint
    });
  };

  responseGetSettingSuccessCall = async (apiRequestCallId: string, responseJson: ResponseDataSettings) => {
    if (apiRequestCallId === this.getSettingsApiCallID) {
      this.settingGetSuccessCallBack(responseJson);
    }
  };

  responseGetUpdateFailureCall = async (apiRequestCallId: string, errorReponse: ResponseDataSettings) => {
    if (apiRequestCallId === this.getSettingsApiCallID) {
      this.settingGetFailureCallBack(errorReponse);
    }
  };

  settingGetSuccessCallBack =(responseJson: ResponseDataSettings) => {
    this.setState({push_notification:responseJson.settings[0].activated})
    this.setState({pause_like:responseJson.settings[1].activated})
    this.setState({pause_booking:responseJson.settings[2].activated})
    this.setState({comment_review:responseJson.settings[3].activated})
    this.setState({hide_acount:responseJson.privacy_settings[0].is_active})
    this.setState({hide_telent_account:responseJson.privacy_settings[1].is_active})
    this.setState({show_address_only_for_approval:responseJson.privacy_settings[2].is_active})
  };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              

  settingGetFailureCallBack = (responseJson: ResponseDataSettings) => {
    this.setState({settingResponse:responseJson})
  };

  async componentDidMount() {
    // Customizable Area Start
    this.getSettingApiFunction()
    this.getNotificationApiFunction()
    // Customizable Area End
  }



  putNotificationUpdateFunction = async () => {
    let apiData = {
      Comment: [
        {
          "name": "push",
          "activated": this.state.comment_push
        },
        {
          "name": "email",
          "activated": this.state.comment_email
        },
        {
          "name": "sms",
          "activated": this.state.comment_sms
        }
      ],
      Tag: [
        {
          "name": "push",
          "activated": this.state.tags_push
        },
        {
          "name": "email",
          "activated": this.state.tags_email
        },
        {
          "name": "sms",
          "activated": this.state.tags_sms
        }
      ],
      Reminder: [
        {
          "name": "push",
          "activated": this.state.reminder_push
        },
        {
          "name": "email",
          "activated": this.state.reminder_email
        },
        {
          "name": "sms",
          "activated": this.state.reminder_sms
        }
      ],
      "More activity about you": [
        {
          "name": "push",
          "activated": this.state.activity_push
        },
        {
          "name": "email",
          "activated": this.state.activity_email
        },
        {
          "name": "sms",
          "activated": this.state.activity_sms
        }
      ]
    }
  
    this.putNotificationApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.notificationMethod,
      endPoint: configJSON.notificationEndPoint,
      body: apiData
    });
  };

  responseNotificationUpdateSuccessCall = async (apiRequestCallId: string, responseJson: PutnotificationResponse) => {
    if (apiRequestCallId === this.putNotificationApiCallID) {
      this.notificationUpdateSuccessCallBack(responseJson);
    }
  };

  responseNotificationUpdateFailureCall = async (apiRequestCallId: string, errorReponse: PutnotificationResponse) => {
    if (apiRequestCallId === this.putNotificationApiCallID) {
      this.notificationUpdateFailureCallBack(errorReponse);
    }
  };

  notificationUpdateSuccessCallBack =(responseJson: PutnotificationResponse) => {
    this.setState({notificationResponse:responseJson})
  };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              

  notificationUpdateFailureCallBack = (responseJson: PutnotificationResponse) => {
    this.setState({notificationResponse:responseJson})
  };

  handleCommentPush = () => {
    this.setState({
      comment_push: !this.state.comment_push
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleCommentEmail = () => {
    this.setState({
      comment_email: !this.state.comment_email
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleCommentSms = () => {
    this.setState({
      comment_sms: !this.state.comment_sms
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleTagsPush = () => {
    this.setState({
      tags_push: !this.state.tags_push
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleTagsEmail = () => {
    this.setState({
      tags_email: !this.state.tags_email
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleTagsSms = () => {
    this.setState({
      tags_sms: !this.state.tags_sms
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleRemindersPush = () => {
    this.setState({
      reminder_push: !this.state.reminder_push
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleRemindersEmail = () => {
    this.setState({
      reminder_email: !this.state.reminder_email
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleRemindersSms = () => {
    this.setState({
      reminder_sms: !this.state.reminder_sms
    },
      () => this.putNotificationUpdateFunction()
    );
  };

  handleActivityPush = () =>{
    this.setState({
      activity_push: !this.state.activity_push
    },
      () => this.putNotificationUpdateFunction()
    );
  }

  handleActivityEmail= () =>{
    this.setState({
      activity_email: !this.state.activity_email
    },
      () => this.putNotificationUpdateFunction()
    );
  }

  handleActivitySms = () =>{
    this.setState({
      activity_sms: !this.state.activity_sms
    },
      () => this.putNotificationUpdateFunction()
    );
  }

  getNotificationApiFunction = async () => {
    this.getNotificationApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.notificationGetEndPoint
    });
  };

  responseGetNotificationSuccessCall = async (apiRequestCallId: string, responseJson: UpdateGetNotificationResponse) => {
    if (apiRequestCallId === this.getNotificationApiCallID) {
      this.notificationGetSuccessCallBack(responseJson);
    }
  };

  responseGetNotificationFailureCall = async (apiRequestCallId: string, errorReponse: UpdateGetNotificationResponse) => {
    if (apiRequestCallId === this.getNotificationApiCallID) {
      this.notificationGetFailureCallBack(errorReponse);
    }
  };

  notificationGetSuccessCallBack =(responseJson: UpdateGetNotificationResponse) => {
    this.setState({comment_push:responseJson.comment[0].activated})
    this.setState({comment_email:responseJson.comment[1].activated})
    this.setState({comment_sms:responseJson.comment[2].activated})
    this.setState({tags_push:responseJson.tag[0].activated})
    this.setState({tags_email:responseJson.tag[1].activated})
    this.setState({tags_sms:responseJson.tag[2].activated})
    this.setState({reminder_push:responseJson.tag[0].activated})
    this.setState({reminder_email:responseJson.tag[1].activated})
    this.setState({reminder_sms:responseJson.tag[2].activated})
    this.setState({activity_push:responseJson.tag[0].activated})
    this.setState({activity_email:responseJson.tag[1].activated})
    this.setState({activity_sms:responseJson.tag[2].activated})
  };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              

  notificationGetFailureCallBack = (responseJson: UpdateGetNotificationResponse) => { 
    this.setState({notificationResponse:responseJson})
  };
 
  // Customizable Area End
}
