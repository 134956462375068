import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { setStorageData } from "../../../framework/src/Utilities";

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}

export interface LoginData {
  meta: {
    token: string;
    refresh_token: string;
    id: number;
  };
}

export interface LoginErrorData{
  errors: [
      {
        failed_login: string;
      }
  ]
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  isSignIn:boolean;
  showPassword:boolean;
  emailField :boolean;
  passwordField:boolean;
  loginError:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  postLoginCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showPassword:false,
      isSignIn:true,
      emailField :false,
      passwordField:false,
      loginError:""
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
      emailField:false,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
      passwordField:false
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

    togglePasswordVisibility = () => {
      this.setState((prevState) => ({ showPassword: !this.state.showPassword }));
    };
  
    toggleForm = (isSignIn: boolean) => {
      this.setState({ isSignIn :false}, () =>{
        this.openEmailRegistrationPage();
      });
    };

    handleApi = (responseJson:LoginData & LoginErrorData,apiRequestCallId:string ) =>{
      if (responseJson && !responseJson.errors) {
        this.responseContactusSuccessCall(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.responseContactusFailureCall(apiRequestCallId, responseJson);
      }
    };

    openEmailRegistrationPage = () => {
      const navigation = new Message(getName(MessageEnum.NavigationMessage));
     navigation.addData(getName(MessageEnum.NavigationTargetMessage),"EmailAccountRegistration");
     navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
     this.send(navigation);
    };

    handleCheckValidation = (event: React.ChangeEvent<HTMLFormElement>) =>{
      event.preventDefault();
      const { password } = this.state;
      const hasSpecialChars = configJSON.passReg.test(password);
      const hasNumbers = configJSON.passOneReg.test(password);
      const isNotEmptyField = password.length > 0;
      const hasNoSpace = configJSON.passSecondReg.test(password);
      if(this.state.email.length === 0 || !configJSON.emailRegx.test(this.state.email)){
        this.setState({emailField:true})
      }
      if(!isNotEmptyField || !hasSpecialChars || !hasNoSpace || !hasNumbers || this.state.password.length < 8){
        this.setState({passwordField:true})
      }
      else{
        this.postLoginFunction()
      }
    }
  
    apiCall = async (data: APIPayloadType) => {
      const { contentType, method, endPoint, body } = data;
  
      const requestMessageCatalogueColorCollection = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      requestMessageCatalogueColorCollection.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      const headerCatalogueColorCollection = {
        "Content-Type": contentType,
      };
      requestMessageCatalogueColorCollection.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerCatalogueColorCollection)
      );
      requestMessageCatalogueColorCollection.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      body && requestMessageCatalogueColorCollection.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessageCatalogueColorCollection.id, requestMessageCatalogueColorCollection);
      return requestMessageCatalogueColorCollection.messageId;
    };

    postLoginFunction = async () => {
      let apiData = {
        data: {
          "type": "email_account",
          "attributes": {
            "email" : this.state.email,
            "password" : this.state.password
        }
      }
     }
      this.postLoginCallID = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.loginAPiMethod,
        endPoint: configJSON.loginApiEndpoint,
        body: apiData,
      });
    };
  
    responseContactusSuccessCall = async (apiRequestCallId: string, responseJson: LoginData & LoginErrorData) => {
      if (apiRequestCallId === this.postLoginCallID) {
        this.cardDetailsSuccessCallBack(responseJson);
      }
    };
  
    responseContactusFailureCall = async (apiRequestCallId: string, errorReponse: LoginData & LoginErrorData) => {
      if (apiRequestCallId === this.postLoginCallID) {
        this.cardDetailsFailureCallBack(errorReponse);
      }
    };
  
    cardDetailsSuccessCallBack = async (responseJson: LoginData) => {
      let userToken = responseJson.meta.token
      let userId = responseJson.meta.id
      await setStorageData("userRole",userToken )
      await setStorageData("userId",userId )
      this.openUserInfoPage()
    };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  
    cardDetailsFailureCallBack = (responseJson: LoginErrorData) => { 
      this.setState({loginError:responseJson.errors[0].failed_login});
      if(this.state.email.length === 0 || !configJSON.emailRegx.test(this.state.email)){
        alert("Please enter required data")
      }
      else{
        alert(this.state.loginError)
      }
    };

    openForgotPasswordPage = () => {
      const navigation = new Message(getName(MessageEnum.NavigationMessage));
     navigation.addData(getName(MessageEnum.NavigationTargetMessage),"ForgotPassword");
     navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
     this.send(navigation);
    };

    openUserInfoPage = () => {
      const navigation = new Message(getName(MessageEnum.NavigationMessage));
     navigation.addData(getName(MessageEnum.NavigationTargetMessage),"UserProfileBasicBlock");
     navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
     this.send(navigation);
    };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.openInfoPage();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      this.handleApi(responseJson,apiRequestCallId);
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
