Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.emailIdtext = "Camila Y. Cuesta";
exports.emailIdtextSecond = "camila@quilabeauty.com";
exports.cropText = "Crop";
exports.nextText = "Next";
exports.creatpostText ="Create new post";
exports.shareText = "Share";
exports.postText = "_ricardo.coll_";
exports.numberText = "0/2, 200";
exports.locationText = "Add location";
exports.accessibilityText = "Accessibility";
exports.settingsText = "Advance settings";
exports.newPostText = "New Post";
exports.collaboratetext =  "Add collaborator";
exports.categoryText = "Add category";
exports.styleText = "Add style tag";
exports.sharebtnText = "Share";
// Customizable Area End