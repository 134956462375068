Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.userInfo = "User Information";
exports.updateInfo = "Update your photo and personal details here.";
exports.userName  = "Name";
exports.emailAddress = "Email address";
exports.role = "Role";
exports.yourPhoto = "Your photo";
exports.displayProfile = "This will be displayed on your profile.";
exports.country = "Country";
exports.timeZone = "Timezone";
exports.userBio = "Bio";
exports.shortIntro = "Write a short introduction.";
exports.portFolio = "Portfolio projects";
exports.snippet = "Share a few snippets of your work.";
exports.display= "Display name";
exports.user = "User name";
exports.cancel = "Cancel";
exports.save = "Save";
exports.tanentAccount = "Want to open a Talent account?";
exports.no = "No";
exports.yes = "Yes";
exports.phoneNumber = "Phone number";
exports.cardName = "Name on card";
exports.cardNumber   = "Card number";
exports.month = "Exp. Month";
exports.year = "Exp. Year";
exports.city = "City";
exports.billing = "Billing Address";
exports.billingAccount = "Billing address same on the account";
exports.cvvNumber  = "CVV number";
exports.editProfile = "Edit profile";
exports.editPicture  = "Edit picture or avatar";
exports.firstName = "First Name";
exports.lastName = "Last Name"
exports.emailRegx = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.personalInfo  = "Personal info";
exports.phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
exports.paswswordChange= "Please enter password";
exports.endpointCountrycode   = "/account/accounts/country_code_and_flag";
exports.methodCountrycode = "GET";
exports.contentType  = "application/json";
exports.updateEndPoint = "account_block/accounts/16/update_details";
exports.updateMethod = "PUT";
exports.updateType = "formData";
exports.updateApiEndPoint = "account_block/accounts/16";
exports.passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
exports.validaPassword = "Please enter atleast eight characters  which contains one number, one special character,one uppercase and one lowercase character";
exports.zipRegex = "/[^\d]|^\s+|\s+$/g"
// Customizable Area End

