Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";
exports.checkEmailText = "Check your email";
exports.verificationText = "We sent a verification link to";
exports.emailText = "camilaycuesta@quila.com";
exports.verifyText = "Verify email";
exports.enterCodeBtnText = "Enter code manually";
exports.receiveEmailtext = "Didn’t receive the email?";
exports.resendText = "Click to resend";
exports.emailVerifiedText  = "Email verified";
exports.resetPassText = "Your password has been successfully reset.";
exports.continueBtnText = "Continue";
exports.loginText = "Click below to log in.";
exports.get = "GET";
exports.otpEndPoint = "account/accounts/email_confirmation?otp=";
exports.resendOtpEndPoint = "account_block/accounts/send_otp";
exports.otpMsg = "Please enter OTP";
exports.otpErorrMSg = "Please enter the 4 digit otp";
// Customizable Area End