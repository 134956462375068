import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";


export interface SignUpData {
  data: {
    id: number,
    type: string,
    attributes: {
      first_name: string,
      last_name: string,
      full_phone_number: number,
      country_code: null,
      phone_number: number,
      email: string,
      activated: boolean,
    },
  },
  meta: {
    token: string,
    message: string,
  }
}

export interface SignUpErrorData {
  errors: [
    {
      email: string,
    },
    {
      full_phone_number: string
    }
  ]
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}

export interface MonthValueInterfaceData {
  id:number;
  monthTitle:string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  fname:string;
  fnameError:boolean;
  isLogin:boolean;
  lname:string;
  emailID:string;
  createPassword:string;
  lnameError:boolean;
  emailError:boolean;
  createPassError:boolean;
  date: string;
  isDay: number;
  isMonth:number;
  isYear:number;
  signUpmsg:string;
  emailMsg:Object;
  phoneMsg:string;
  year: string;
  month: string;
  day: string;
  selectYear: number;
  selectMonth: number;
  selectDay: number;
  dayValue: Array<number>;
  monthValue: Array<MonthValueInterfaceData>;
  yearValue: Array<number>;
  dobMsg:boolean;
  monthMsg:boolean;
  yearMsg:boolean;
  dobPlaceholder:string | number;
  monthPlaceholder:string |number  |undefined ;
  yearPlaceholder:string |number  | undefined;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  postSignupCallID: string = "";
emailRegx:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      fname: "",
      fnameError: false,
      isLogin: false,
      lname: "",
      emailID: "",
      createPassword: "",
      lnameError: false,
      emailError: false,
      createPassError: false,
      date: "",
      isDay: 0,
      isMonth: 0,
      isYear: 0,
      signUpmsg:"",
      emailMsg:{},
      phoneMsg:"",
      year: "",
      month: "",
      day: "",
      selectYear: new Date().getFullYear(),
      selectMonth: new Date().getMonth()+1,
      selectDay: new Date().getDate(),
      dayValue: [],
      monthValue: [
        { id: 1, monthTitle: "January" },
        { id: 2, monthTitle: "February" },
        { id: 3, monthTitle: "March" },
        { id: 4, monthTitle: "April" },
        { id: 5, monthTitle: "May" },
        { id: 6, monthTitle: "June" },
        { id: 7, monthTitle: "July" },
        { id: 8, monthTitle: "August" },
        { id: 9, monthTitle: "September" },
        { id: 10, monthTitle: "October" },
        { id: 11, monthTitle: "November" },
        { id: 12, monthTitle: "December" },
      ],
      yearValue: [],
      dobMsg: false,
      monthMsg: false,
      yearMsg: false,
      dobPlaceholder: "Date",
      monthPlaceholder: "Month",
      yearPlaceholder: "Year"
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
this.emailRegx=configJSON.emailRegx;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

        this.handleSignupApi(message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    
    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {};

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {};

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

handleSignupApi = (message: Message) =>  {
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      this.responsesignUpSuccessCall(apiRequestCallId, responseJson);
    } else if (responseJson && responseJson.errors) {
      this.responsesignUpFailureCall(apiRequestCallId, responseJson);
    }
  }
};

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;

    const requestMessageCatalogueColorCollection = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headerCatalogueColorCollection = {
      "Content-Type": contentType,
    };
    
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerCatalogueColorCollection)
    );
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageCatalogueColorCollection.id, requestMessageCatalogueColorCollection);
    return requestMessageCatalogueColorCollection.messageId;
  };
  postSignUpFunction = async () => {
      let apiData = {
        data: {
          "type": "email_account",
          attributes: {
            "first_name": this.state.fname,
            "last_name": this.state.lname,
            "full_name":`${this.state.fname + this.state.lname}`,
            "email": this.state.emailID,
            "full_phone_number": 7328912358,
            "password": this.state.createPassword,
            "date_of_birth": `${this.state.selectDay}-${this.state.selectMonth}-${this.state.selectYear}`
          }
        },
      }

    this.postSignupCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeAddDetail,
      endPoint: configJSON.signUpApiEndpoint,
      body: apiData,
    });
  };


  responsesignUpSuccessCall = async (apiRequestCallId: string, responseJson: SignUpData & SignUpErrorData) => {
    if (apiRequestCallId === this.postSignupCallID) {
      this.cardDetailsSuccessCallBack(responseJson);
    }
  };

  responsesignUpFailureCall = async (apiRequestCallId: string, errorReponse: SignUpData & SignUpErrorData) => {
    if (apiRequestCallId === this.postSignupCallID) {
      this.cardDetailsFailureCallBack(errorReponse);
    }
  };

  cardDetailsSuccessCallBack = (responseJson: SignUpData) => {
    this.setState({signUpmsg:responseJson.meta.message})
    this.handleOtp();
  };

  cardDetailsFailureCallBack = (responseJson: SignUpErrorData) => {
    this.setState({emailMsg:responseJson.errors[0].email})
  };

  setFname = (text:string) => {
    const newValue = text
    const regex = configJSON.fnameReg
    const filteredValue = newValue.replace(regex, '');
    const capitalizedValue = filteredValue.charAt(0).toUpperCase() + filteredValue.slice(1);
    this.setState({
        fname: capitalizedValue,
      fnameError:false
    });
  };

  setLname = (text:string) =>{
    const nameValue = text
    const regex = configJSON.fnameReg
    const filteredDataValue = nameValue.replace(regex, '');
    const capitalizedLnameValue = filteredDataValue.charAt(0).toUpperCase() + filteredDataValue.slice(1);
    this.setState({lname:capitalizedLnameValue,
      lnameError:false 
    });
  };

  setEmail = (text:string) =>{
    this.setState({emailID:text,
      emailError:false });
  };

  setPassword = (text:string) =>{
    this.setState({createPassword:text,
      createPassError:false});
  };

  toggleForm = (isLogin:boolean) => {
    this.setState({ isLogin: true }, () => {
      this.openEmailLoginPage()
    });
  };

  handleCheck = (event: React.ChangeEvent<HTMLFormElement>) =>{
    event.preventDefault();
    const { createPassword } = this.state;
    const hasSpecialChar = configJSON.passReg.test(createPassword);
    const hasNumber = configJSON.passOneReg.test(createPassword);
    const isNotEmpty = createPassword.length > 0;
    const hasNoSpaces = configJSON.passSecondReg.test(createPassword);
    const passChar = this.state.createPassword.length < 8 
    if(this.state.fname.length === 0){
      this.setState({fnameError:true});
    }
    if(this.state.lname.length === 0){
      this.setState({lnameError:true});
    }
    if(this.state.emailID.length === 0 || !configJSON.emailRegx.test(this.state.emailID)){
      this.setState({emailError:true});
    }
    if (!isNotEmpty || !hasSpecialChar || !hasNoSpaces || !hasNumber || passChar) {
      this.setState({createPassError:true});
    } 
    if (this.state.isDay === 0) {
      this.setState({ dobMsg: true });
    }
    if (this.state.isMonth === 0) {
      this.setState({ monthMsg: true });
    }
    if (this.state.isYear === 0) {
      this.setState({ yearMsg: true });
    }
    else{
     this.postSignUpFunction()
    }
  };

  openEmailLoginPage = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  calculatePreviousYears = () => {
    const { selectYear } = this.state;
    const years = [];
    for (let i = 0; i <= 100; i++) {
      years.push(selectYear - i);
    }
    this.setState({ yearValue: years });
  };

  getDaysInMonth = (year: number, month: number) => {
    const lastDay = new Date(year, month, 0).getDate();
    console.log("Last", lastDay)
    const daysArray = Array.from({ length: lastDay }, (_, index) => index + 1);
    this.setState({
      dayValue: daysArray,
    });
  }; 

  handleDateChange = (event: { target: { value: number; }; }) => {
    this.setState({
      selectDay: event.target.value,
      dobMsg: false,
      dobPlaceholder: Number(event.target.value),
      isDay: event.target.value
    })
    if (!this.state.selectDay) {
      this.setState({
        dobMsg: false
      });
    }
  };

  handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedMonth = event.target.value as string;
    this.setState({
      selectMonth: Number(selectedMonth),
      monthMsg:false,
      isMonth: Number(selectedMonth),
      monthPlaceholder: selectedMonth,
    }, () => {
      if (!this.state.selectMonth) {
        this.setState({
          monthMsg: false
        })
      }
        this.getDaysInMonth(this.state.selectYear, this.state.selectMonth);
    });
  };
  
  handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectYear = event.target.value as string;
    this.setState({
      selectYear: Number(selectYear),
      yearPlaceholder: selectYear,
      isYear: Number(selectYear)
    }, () => {
      if (this.state.selectYear) {
        this.setState({
          yearMsg: false
        });
      }
      this.getDaysInMonth(this.state.selectYear, this.state.selectMonth);
    });
  };

  async componentDidMount () {
    this.calculatePreviousYears();
    this.getDaysInMonth(this.state.selectYear, this.state.selectMonth);
  }
  handleOtp = () =>{
    const msg: Message = new Message(
      getName(MessageEnum.otpScreenNavigationPropsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.sendEmployeeIdProps),this.state.emailID);
    this.send(msg);
  }


  // Customizable Area End
}
