export const userProfile = require("../assets/user-profile.png");
export const homeIcon = require("../assets/HomeIcon.svg");
export const exploreIcon = require("../assets/Explore.svg");
export const notificationIcon = require("../assets/Notification.svg");
export const msgIcon = require("../assets/Message.svg");
export const appointmentIcon = require("../assets/Appointment.svg");
export const savedIcon = require("../assets/Saved.svg");
export const moodIcon = require("../assets/Mood.svg");
export const settingsIcon = require("../assets/Settings.svg");
export const porifleIcon = require("../assets/Profile.svg");
export const createPostIcon = require("../assets/CreatePost.svg");
export const supportsIcon = require("../assets/Support.svg");
export const quilaLogo = require("../assets/QuilaLogo.png");
export const logoutIcon = require("../assets/Logout.svg");
export const profileImg = require("../assets/profileImg.png");
export const albumImg = require("../assets/album.svg");
export const backIcon = require("../assets/backIcon.svg");
export const copyIcon = require("../assets/copyIcon.svg");
export const zoomInIcon = require("../assets/zoomIn.svg");
export const postImg = require("../assets/Getty_Images.png");
export const smileImg = require("../assets/smileIcon.svg");
export const mapIcon = require("../assets/mapIcon.svg");
export const downIcon = require("../assets/downArrow.svg");
export const backArrow = require("../assets/backArrow.svg");
export const rightArrow = require("../assets/RightArrow.svg");
export const cutomerIcon = require("../assets/customer.svg");
export const locationIcon = require("../assets/locationicon.svg");
export const categoryIcon = require("../assets/categoryIcon.svg");
