export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const toggleImgGrey = require("../assets/greyToggle.png");
export const toggleImgPurple = require("../assets/purpleToggle.png");
export const backIcon = require("../assets/backIcon.svg");
export const downArrow = require("../assets/vector.svg");
export const homeIcon = require("../assets/homeIcon.svg");
export const dashboardIcon = require("../assets/dashBoard.svg");
export const projectIcon = require("../assets/projectsIcon.svg");
export const reportingIcon = require("../assets/reporting.svg");
export const taskIcon = require("../assets/taskIcon.svg");
export const userIcon = require("../assets/userIcon.svg");
export const quilaImg = require("../assets/QuilaLogo.png");
export const searchIcon = require("../assets/searchIcon.svg");
export const supportIcon = require("../assets/supportIcon.svg");
export const settingIcon = require("../assets/settingsIcon.svg");
export const profileImg = require("../assets/profileImg.png");
export const logoutImg = require("../assets/logOut.svg");
export const backIconImg = require("../assets/backBlackIcon.svg");