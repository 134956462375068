import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  TextField,
  Divider,
  styled,
  Hidden
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {
  quilaLogo,
  googleLogo,
  sideImg,
  backIcon,
  visibleEyeIcon,
  visibleEyeRedIcon
} from "./assets";
export const configJSON = require("./config");

const EmailAccount_Main_Container = styled(Box)({
  height: '100%',
});

const Login_Main_Container = styled(Box)({
  "@media (max-width: 599px)":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    padding: "10px 20px 95px",
  }
});

const LoginConatianer = styled(Box)({
  padding:"25px 18% 0",
  justifyContent: "center",
  alignItems: "center",
  display: 'flex',
  margin:"32px",
  marginTop: "5%",
  "@media (max-width: 599px)":{
    padding:"55px 10% 0",
    justifyContent: "center",
    alignItems: "center",
    display: 'flex',
  },
  "@media (max-width: 435px)":{
    padding:"10px 5% 0",
    justifyContent: "center",
    alignItems: "center",
    display: 'flex',
  }
})

const LoginSignup = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "inherit",
  marginBottom: "32px"
})

const LoginText = styled(Typography)({
  textAlign: "center",
  padding: "10px 0",
  cursor: "pointer",
  width: "48%",
  fontSize: "17px",
  fontWeight: "bold",
})

const DividerContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: '7%'
})

const MainDivider = styled(Divider)({
  width: '50%',
  backgroundColor: '#D9D9D9',
  height: '1px',
})

const OrText = styled(Typography)({
  margin: '0 10px',
  color: '#7d7e7e'
})

const GoogleContainer = styled(Box)({
  border: "1px solid #D9D9D9",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "44px",
  borderRadius: "6px",
  marginBottom: "10pxx",
  cursor: "pointer",
  "@media (max-width: 435px)":{
    border: "1px solid #D9D9D9",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "44px",
    borderRadius: "6px",
    marginBottom:"20px"
  }
})

const GoogleText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "140%"
})

const AccountContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
})

const ForgetContainer = styled(Box)({
  justifyContent: 'center',
  display: 'flex'
})

const AccountText = styled(Typography)({
  color:"grey",
  fontSize: "14px",
  marginTop:"2rem"
})

const ForgetText = styled(Button)({
  fontSize: '14px',
  color: '#6551ea',
  marginTop: '0.5rem',
  fontWeight: 500,
  textTransform: 'none',
  alignItems: 'center'
})

const LoginButton = styled(Button)({
  marginTop: '7rem',
  textTransform: 'none',
  backgroundColor: '#0e1518',
  height: '45px',
  color:"#fff",
  fontSize:"15px",
  fontWeight:500,
  "&:hover":{
    backgroundColor: '#0e1518',
  }
})

const SignUpText = styled(Typography)({
  fontSize:" 14px",
  color:"#6551EA",
  marginTop: "2rem",
  textTransform: "none",
  marginLeft: "0.2rem",
  fontWeight:500,
  fontStyle:"normal"
})

const SideImageeeContainer = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  opacity: "1",
  display: 'flex',
  "@media (max-width: 599px)":{
    display: 'none',
  }
})

const GoogleLogo = styled("img")({
  position: "absolute",
  left: "12px",
  top: "12px"
})

const QualImage = styled("img")({
  width: "21%",
  xheight: "10x0%",
  objectFit: "cover",
  "@media (max-width:598px)": {
    flexDirection: "column",
    width: "35%"
  },
});

const BackIcon = styled("img")({
  display:"none",
  "@media (max-width: 599px)":{
    padding:"55px 10% 0",
    justifyContent: "center",
    alignItems: "center",
    display: 'flex',
  },
  "@media (max-width: 435px)":{
    padding:"10px 5% 0",
    justifyContent: "center",
    alignItems: "center",
    display: 'flex',
    marginTop:"20px"
  }
})

const webStyle = {
  textInputStyle: {
    borderRadius: "10px",
    border: "1px solid #E53935",
    background: "rgba(229, 57, 53, 0.15)",
  },
  textInputView: {
    "&.MuiOutlinedInput-input": {
      borderRadius: "20px"
    }
  },
  textInputStyleBorder: {
    borderRadius: "20px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px"
    }
  }
};

const TextInput = styled(TextField)({
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "7px"
    }
});

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start

  renderLoginBox = ()=>{
    const getBorderStyle = () => {
      return this.state.isSignIn ? "1px solid #6551ea" : "1px solid #757676";
    };
    
    const getTextColor = () => {
      return this.state.isSignIn ? "#6551ea" : "#757676";
    };
    return(
      <LoginSignup>
        <LoginText style={{
          borderBottom: getBorderStyle(),
          color: getTextColor(),
        }}>
          {configJSON.labelTitle}
        </LoginText>
        <LoginText style={{ borderBottom: this.state.isSignIn ? "1px solid #757676" : "1px solid #6551ea", color: this.state.isSignIn ? "#757676" : "#6551ea", }}
          data-test-id="toggleID"
          onClick={() => this.toggleForm(false)}>{configJSON.labelSignup}</LoginText>
      </LoginSignup>
    )
  }

  renderEmailInput = ( )=>{
    return (
      <>
        {this.state.emailField ?
          <TextInput
            data-test-id="emailInputID"
            error={this.state.emailField}
            margin="normal"
            fullWidth
            id="username"
            label={this.state.email ? "" : "Phone number, email, or username"}
            variant="outlined"
            InputLabelProps={{
              disableAnimation: true,
              shrink: false,
              style: {
                color: "red",
                opacity: 1
              }
            }}
            InputProps={{
              style: {
                color: "red",
                backgroundColor: "rgba(229, 57, 53, 0.15)"
              },
            }}
            onChange={(e) => this.setEmail(e.target.value)}
            value={this.state.email}
          /> :
          <TextInput
            data-test-id="emailInputID"
            margin="normal"
            fullWidth
            label={this.state.email ? "" : "Phone number, username, or email"}
            variant="outlined"
            InputLabelProps={{
              disableAnimation: true,
              shrink: false,
              style: {
                color: "#757676",
                opacity: 1
              }
            }}
            inputProps={{
              style: { color: "#757676" }
            }}
            onChange={(e) => this.setEmail(e.target.value)}
            value={this.state.email}
          />}
        </>
    )
  };

// Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <EmailAccount_Main_Container>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <BackIcon src={backIcon}/>
              <Login_Main_Container>
                <QualImage src={quilaLogo} alt="quila" />
                <LoginConatianer>
                  <form  onSubmit={this.handleCheckValidation} data-test-id="submitTestID">
                    <Box>
                      {this.renderLoginBox()}
                        {this.renderEmailInput()}
                      {this.state.passwordField ?
                        <TextField
                          data-test-id="passwordInputID"
                          error={this.state.passwordField}
                          margin="normal"
                          fullWidth
                          id="password"
                          label={this.state.password ? "" :"Password"}
                          type={this.state.showPassword ? "text" : "password"}
                          variant="outlined"
                          onChange={(e) => this.setPassword(e.target.value)}
                          value={this.state.password}
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink:false,
                            style:{
                              color:"red",
                              opacity:1
                            }
                          }}
                          InputProps={{
                            style: {
                              color: "red",
                              borderRadius:"7px",
                              backgroundColor:"rgba(229, 57, 53, 0.15)"
                            },
                            endAdornment: (
                              <IconButton onClick={this.togglePasswordVisibility} edge="end" >
                                {this.state.showPassword ? <VisibilityOff style={{color:"#E53935"}}/> : <img src={visibleEyeRedIcon} />}
                              </IconButton>
                            ),
                          }}
                        /> :
                        <TextField
                          data-test-id="passwordInputID"
                          margin="normal"
                          fullWidth
                          id="password"
                          label={this.state.password ? "" :"Password"}
                          type={this.state.showPassword ? "password" : "text"}
                          variant="outlined"
                          onChange={(e) => this.setPassword(e.target.value)}
                          value={this.state.password}
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                            style: { color: "#757676" }
                          }}
                          InputProps={{
                            style: {
                              color: "#757676",
                              borderRadius:"7px"
                            },
                            endAdornment: (
                              <IconButton onClick={this.togglePasswordVisibility} edge="end" >
                                {this.state.showPassword ? <VisibilityOff/> : <img src={visibleEyeIcon}/>}
                              </IconButton>
                            ),
                          }}
                        />}

                      <DividerContainer mt={2} mb={2}>
                        <MainDivider />
                        <OrText variant="body2">
                          {configJSON.labelOr}
                        </OrText>
                        <MainDivider />
                      </DividerContainer>
                      <GoogleContainer>
                        <GoogleLogo src={googleLogo} alt="google" />
                        <GoogleText>{configJSON.btnTxtSocialLogin}</GoogleText>
                      </GoogleContainer>
                      <Hidden xsDown>
                        <AccountContainer>
                          <AccountText>{configJSON.forgotPassword}</AccountText>
                          <SignUpText onClick={this.openEmailRegistrationPage}>{configJSON.labelSignup}</SignUpText>
                        </AccountContainer>
                      </Hidden>
                      <ForgetContainer>
                        <ForgetText onClick={this.openForgotPasswordPage}
                          data-test-id="fogotPassTestID">
                          {configJSON.forgotPassTwo}
                        </ForgetText>
                      </ForgetContainer>
                      <LoginButton
                        type="submit"
                        fullWidth
                        variant="contained">
                        {configJSON.labelTitle}
                      </LoginButton>
                    </Box>
                  </form>
                </LoginConatianer>
              </Login_Main_Container>
            </Grid>
            <Grid item xs={false} sm={6} md={6} lg={6} xl={6}>
              <SideImageeeContainer src={sideImg} alt="login" />
            </Grid>
          </Grid>
        </EmailAccount_Main_Container>
      </>
      // Customizable Area End
    );
  }
}
