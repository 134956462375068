import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    styled,
    Hidden,
    TextField
} from "@material-ui/core";
import { leftArrow, keyImage, quilaImage, blackArrow, email } from "./assets";
// Customizable Area End
import ForgotPasswordController, {
    Props, configJSON
} from "./ForgotPasswordController";

export default class ForgetPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderForget = () => {
        return (
            <Grid>
                <BackIcon src={blackArrow} />
                <QualImage src={quilaImage} alt="quila" />
                <MainContainer>
                    <ForgetContainer>
                        <KeyContainer>
                            <KeyImage src={keyImage} alt="Key Image"
                            />
                        </KeyContainer>
                        <ForgetText>
                            {configJSON.forgetPassword}
                        </ForgetText>
                        <SendText>
                            {configJSON.emailSend}
                        </SendText>
                        <TextForm noValidate onSubmit={(event)=>this.handleButtonClick(event)} data-test-id="submitTestID">
                            {this.renderTextInput()}
                            <SubmitButton
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                {configJSON.managerPasswordMsg}
                            </SubmitButton>
                        </TextForm>
                        <Hidden xsDown>
                            <BackButtonContainer data-test-id ="BacktoLogintestId"container onClick={this.openEmailLoginPage}>
                                <LeftArrowImage src={leftArrow} alt="Back to Login" />
                                <BackText>{configJSON.backToLogin}</BackText>
                            </BackButtonContainer>
                        </Hidden>
                    </ForgetContainer>
                </MainContainer>

            </Grid>
        )
    };

    renderTextInput = () => {
        return (
            <EmailTextInput
                data-test-id="emailInputID"
                error={this.state.emailFormatOne}
                required={this.state.emailFormatOne ? true : false}
                label={this.state.inputValue ? "" : "Enter your email"}
                variant="outlined"
                className="input_field_config"
                value={this.state.inputValue}
                fullWidth
                onChange={(e) => this.setEmail(e.target.value)}
                InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    style: {
                        color: this.state.emailFormatOne ? "red" : "#757676",
                        opacity: 1
                    }
                }}
                InputProps={{
                    style: {
                        color: this.state.emailFormatOne ? "red" : "#757676",
                        border: this.state.emailFormatOne ? "1px solid red" : "",
                        backgroundColor: this.state.emailFormatOne ? "rgba(229, 57, 53, 0.15)" : "#fff",
                        borderRadius: "7px"
                    }
                }}
            />
        )
    }

    renderOpenModal = () => {
        return (
            <Grid>
                <Button onClick={this.handleCloseModal}>
                    <BackIcon src={blackArrow} />
                </Button>
                <QualImage src={quilaImage} alt="quila" />
                <MainContainer>
                    <EmailContainer>
                        <KeyContainer>
                            <KeyImage src={email} alt="Key Image"
                            />
                        </KeyContainer>
                        <ForgetText>
                            {configJSON.checkEmail}
                        </ForgetText>
                        <SendText >
                            {configJSON.resetNewLink}
                        </SendText>
                        <EmailType>{this.state.inputValue}</EmailType>
                        <a href={"https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox"} target="_blank" rel="noopener noreferrer">
                        <EmailButton
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            {configJSON.openEmailApp}
                        </EmailButton>
                        </a>
                        <ResendContainer container spacing={2} >
                            <EmailText>{configJSON.dontRecieveEmail}</EmailText>
                            <ResendText onClick={(event)=>this.handleButtonClick(event)} data-test-id="submitTestID">{configJSON.clickToResend}</ResendText>
                        </ResendContainer>
                    </EmailContainer>
                </MainContainer>
            </Grid>
        )
    }


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box>
                {this.state.isModalOpen ? this.renderOpenModal() : this.renderForget()}
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainContainer = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%'
})

const ForgetContainer = styled(Grid)({
    padding: "16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '22px',
    "@media (max-width: 599px)": {
        marginTop: '-250px',
    },
})

const TextForm = styled("form")({
    width: '390px',
    marginTop: "15px",

})

const SubmitButton = styled(Button)({
    margin: '18px 0',
    backgroundColor: '#101828',
    color: 'white',
    textTransform: "none",
    height: '44px',
    fontWeight: 600,
    top: '16px',
    "&:hover": {
        backgroundColor: '#101828'
    }
})

const BackButtonContainer = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    marginTop: '20px'
})

const LeftArrowImage = styled("img")({
    marginRight: '6px'
})

const BackText = styled(Typography)({
    color: "#757676",
    fontWeight: 600,
    fontSize: '14px'
})

const SendText = styled(Typography)({
    marginTop: '16px',
    textAlign: 'center',
    color: '#757676',
    fontSize: '14px',
    fontWeight: 400
})

const KeyImage = styled('img')({

})

const BackIcon = styled("img")({
    display: "none",
    "@media (max-width: 599px)": {
        padding: "55px 10% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
    },
    "@media (max-width: 435px)": {
        padding: "10px 5% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        marginTop: "20px"
    }
})

const KeyContainer = styled(Grid)({
    width: "56px",
    height: '56px',
    border: '1px solid #EAECF0',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '12px',
    alignItems: 'center'
})

const ForgetText = styled(Typography)({
    fontSize: '24px',
    fontWeight: 600,
    color: '#101828',
    marginTop: '20px'
})

const QualImage = styled("img")({
    margin: '4px',
    objectFit: 'cover',
    display: 'flex',
    "@media (max-width: 599px)": {
        display: 'none',
    },

})

const EmailTextInput = styled(TextField)({
    marginTop:'25px'
})

const EmailButton = styled(Button)({
    margin: '18px 0',
    backgroundColor: '#101828',
    color: 'white',
    textTransform: "none",
    height: '44px',
    fontWeight: 600,
    top: '16px',
    marginTop: "8px",
    width: '358px',
    "&:hover": {
        backgroundColor: '#101828'
    }
})

const EmailText = styled(Typography)({
    color: '#475467',
    fontSize: '14px',
    fontWeight: 400
})

const ResendText = styled(Typography)({
    color: '#6551EA',
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '6px',
    cursor:"pointer"
})

const EmailContainer = styled(Grid)({
    padding: "16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '358px',
    "@media (max-width: 599px)": {
        marginTop: '-320px'
    },
})

const ResendContainer = styled(Grid)({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '40px',
    width: '358px'
})

const EmailType = styled(Typography)({
    color: '#757676',
    fontWeight: 600
})


const TextContainer = styled(Box)({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .login_box": {
        width: "358px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        "@media (max-width:598px)": {
            width: "300px",
        },
    },
})

// Customizable Area End
