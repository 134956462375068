import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible,avatar } from "./assets";
import React, { ChangeEvent, RefObject } from "react";
import { isNaN } from "formik";
import { getStorageData } from "../../../framework/src/Utilities";


interface SecondAttributes {
  type: string;
  user_type: number;
  exp_year: string;
  cvv_number: number;
  billing_address_same_acc: boolean;
  city: string;
  user_type_name: string;
  created_at: string;
  updated_at: string;
  profile_img: string;
  country: string;
  timezone: string;
  bio: string;
  role_name: string;
  name_on_card: string;
  card_number: number;
  exp_month: string;
  state: string;
  zip_code: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  activated: boolean;
  full_phone_number: string;
  talent_account: boolean;
  billing_cvv_number: number;
}

interface SecondData {
  id: string;
  type: string;
  attributes: SecondAttributes;
}

interface SecondResponseData {
  data: SecondData;
}

export interface CountryData {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  };
}

export interface Attributes {
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  activated: boolean;
  full_phone_number: string;
  type: string;
  user_type: number;
  user_type_name: string;
  created_at: string;
  updated_at: string;
  profile_img: string;
  country: string;
  timezone: string;
  bio: string;
  role_name: string;
  name_on_card: string;
  card_number: number;
  exp_month: string;
  exp_year: string;
  cvv_number: number;
  billing_address_same_acc: boolean;
  city: string;
  state: string;
  zip_code: string;
  talent_account: boolean;
  billing_cvv_number: number;
}

export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface ResponseData {
  data: Data;
}

export interface Country {
  isoCode: string;
  name: string;
  timezones: {
    gmtOffsetName: string;
    tzName:string;
    abbreviation:string;
  }[];
}

export interface ApiResponse {
  data: CountryData[];
}

export interface ApiData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: object,
  type:string
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  countryCode:string;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  isModalOpen: boolean;
  inputValue: string;
  emailFormatOne: boolean;
  firstnameError: boolean;
  lastnameError: boolean;
  userFirstName: string;
  userLastname: string;
  roleName: string;
  bioData: string;
  roleError: boolean;
  bioError: boolean;
  phoneError: boolean;
  phoneNumb: string;
  display: string;
  city: string;
  cvvNumber: string;
  zipCode: string;
  cityState: string;
  cvvNumb: string;
  expYear: string;
  expMonth: string;
  cardname: string;
  changePassword:string;
  cardNumb: string;
  checked: boolean;
  username: string;
  userId:string;
  profileImageFile: File | null;
  uploadedFiles: { file: File; uploaded: boolean }[];
  imagefileUrls:File[];
  uploadImages:File[];
  fileUrls: string[];
  uploadProgress: number[];
  passwordChange: string;
  passwordError: boolean;
  selectedMonth: string;
  selectedYear: number | string;
  errors: { [key: string]: string };
  displayName: string;
  nameUser: string;
  text: string;
  isButtonClicked: boolean;
  isBold: boolean;
  isItalic: boolean;
  linkUrl: string;
  numberedListCount: number;
  emailAddress: string;
  numberPhone: string;
  passwordChnage: string;
  nameCard: string;
  numbCard: string;
  addressName:string;
  cityName:string;
  stateName:string;
  zipNumber:string;
  numbCvv: string;
  bioField:string;
  imgUpload:string;
  fileUpload:string;
  monthExp: string;
  yearExp: string;
  months: string[];
  country: string;
  timeZone: string;
  countryCodeFilterList:Array<CountryData> ;
  selectedCountryId:string;
  countryCodeEnabled: boolean;
  selectedCountry: string;
  selectTimeZone:string;
  profileImg:string;
  error: string;
    saveButtonDisable: boolean;
  full_name: string;
  email_id: string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  fileInputRef: RefObject<HTMLInputElement>;
  imageInputRef: RefObject<HTMLInputElement>;
  userAttr: any;
  UpdationAPICallId:string="";
  UpdationSecondApi:string="";
  GetCountryCodeApicallid:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      error: '',
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      countryCode:"",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      isModalOpen: false,
      inputValue: "",
      emailFormatOne: false,
      firstnameError: false,
      lastnameError: false,
      userFirstName: "",
      userLastname: "",
      roleName: "",
      bioData: "",
      roleError: false,
      bioError: false,
      phoneNumb: "",
      display: "",
      cvvNumber: "",
      zipCode: "",
      cityState: "",
      cvvNumb: "",
      expYear: "",
      city: "",
      changePassword:"",
      selectedCountry: "",
      selectTimeZone:"",
      expMonth: "",
      cardNumb: "",
      checked: false,
      userId:"",
      cardname: "",
      username: "",
      profileImageFile:null,
      phoneError: false,
      passwordChange: "",
      selectedYear: "",
      displayName: "",
      nameUser: "",
      emailAddress: "",
      uploadedFiles: [],
      uploadProgress: [],
      uploadImages:[],
      imagefileUrls:[],
      fileUrls: [],
      numberPhone: "",
      passwordChnage: "",
      selectedMonth: "",
      passwordError: false,
      errors: {},
      months: [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December',
      ],
      addressName:"",
      
      cityName:"",
      stateName:"",
      zipNumber:"",
      nameCard: "",
      numbCard: "",
      numbCvv: "",
      bioField:"",
      imgUpload:"",
      fileUpload:"",
      monthExp: "",
      yearExp: "",
      country: "",
      timeZone: "",
      text: "",
      isButtonClicked: false,
      isBold: false,
      isItalic: false,
      linkUrl: "",
      numberedListCount: 1,
      countryCodeFilterList:[],
      selectedCountryId: '',
      countryCodeEnabled: true,
      saveButtonDisable: false,
      profileImg:avatar,
      email_id: "",
      full_name: ""

    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    this.fileInputRef = React.createRef();
    this.imageInputRef = React.createRef();
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";

              this.setState({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
              });

              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired", "Please Log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success

            this.showAlert("Success", "Profile updated successfully.");
            this.enableDisableEditPassword(false);
            this.getUserProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }
    this.handleForgetPasswordApi(message)

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }


  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };
  responsescountrycodeSuccessCall = async (apiRequestCallId: string, responseJson: ApiResponse) => {
    if (apiRequestCallId === this.GetCountryCodeApicallid) {
      this.countrycodeSuccessCallBack(responseJson);
    }
  };

  countrycodeSuccessCallBack =async (responseJson: ApiResponse) => {
        this.setState({ countryCodeFilterList: responseJson.data });
  };

  handleForgetPasswordApi = (message: Message) =>  {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.UpdationAPICallId) {
          this.responseupdateSuccessCall(apiRequestCallId,responseJson)
        }
      }
      if (responseJson) {
        this.responsescountrycodeSuccessCall(apiRequestCallId, responseJson);
      } 
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.UpdationSecondApi) {
          this.UpdationSecondApiSuccessCall(apiRequestCallId,responseJson)
        }
      }
    }
  };



  apiCall = async (data: ApiData) => {

    const { contentType, method, endPoint, body, type } = data
    const header = {
      'Content-Type': contentType,
      token: await getStorageData("userRole")
    }
    const countryCoderequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    countryCoderequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    countryCoderequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    countryCoderequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      countryCoderequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : countryCoderequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(countryCoderequestMessage.id, countryCoderequestMessage);
    return countryCoderequestMessage.messageId;
  }
  
  handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedCountryId: event.target.value as string });
};



  countryCodeFunction = async () => {
    this.GetCountryCodeApicallid = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.methodCountrycode,
      endPoint: configJSON.endpointCountrycode,
      type:""
    });
  };

   handleIconClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };


  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files;
        if (files) {
      const allowedTypes = ['application/pdf', 'video/mp4', 'application/octet-stream']; 
        const validFiles = Array.from(files).filter(file => allowedTypes.includes(file.type) || file.name.endsWith('.fig'));
                if (validFiles.length === 0) {
            this.setState({ error: 'Only PDF, MP4, and FIG files are allowed.' });
            event.target.value = "";
            return;
        }
        const filesArray = Array.from(files).map((file: File) => ({
            file,
            uploaded: false,
        }));
        const fileUrls = filesArray.map((file) => URL.createObjectURL(file.file));
        this.setState({
            uploadedFiles: [...this.state.uploadedFiles, ...filesArray],
            fileUrls: [...this.state.fileUrls, ...fileUrls],
            uploadProgress: [...this.state.uploadProgress, ...new Array(filesArray.length).fill(0)],
            fileUpload:"",
            error: "", 
        }, () => {
          this.uploadFiles();
        });
    }
};


uploadFiles = () => {
  const { uploadedFiles } = this.state;
  uploadedFiles.forEach((fileObject, index) => {
    if (!fileObject.uploaded) {
      this.setState({
        uploadedFiles: uploadedFiles.map((file, i) =>
          i === index ? { ...file, uploaded: true } : file
        )
      }, () => {
        let progress = 0;
        const interval = setInterval(() => {
          progress += 5; 
          if (progress > 100) {
            progress = 100;
            clearInterval(interval);
          }
          this.updateProgress(index, progress);
        }, 500);
      });
    }
  });
};

updateProgress = (index:number, progress:number) => {
  this.setState({
    uploadProgress: this.state.uploadProgress.map((value, idx) =>
      idx === index ? progress : value
    )
  });
};


handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const isChecked = event.target.checked;
  this.setState({ checked: isChecked }, () => {
    if (isChecked) {
      this.setState({ cvvNumber: this.state.cvvNumb });
    } else {
      this.setState({ cvvNumber: '' }); 
    }
  });
};


  handleImageChange = async(event: ChangeEvent<HTMLInputElement>) => {  
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
  
    if (event.target.files && event.target.files[0]) {
      if (!allowedTypes.includes(event.target.files && event.target.files[0].type)) {
        this.setState({ error: 'Only JPG, JPEG, PNG, and SVG files are allowed.', profileImg: avatar,},()=>{          
        });
        if (event.target.value) {
          event.target.value = "";
        }
      } else {
        this.setState({ error: "", profileImg: URL.createObjectURL(event.target.files[0]), imgUpload:"",profileImageFile: event.target.files[0],});
      }
    }
  };

  handleUploadClick = () => {
    if (this.imageInputRef.current) {
      this.imageInputRef.current.click();
    }
  };

  formatFileSize=(size: number): string =>{
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  handleDelete=(index: number)=> {
    const { uploadedFiles, fileUrls } = this.state;
    const newUploadedFiles = [...uploadedFiles];
    const newFileUrls = [...fileUrls];
    
    newUploadedFiles.splice(index, 1);
    newFileUrls.splice(index, 1);

    this.setState({
      uploadedFiles: newUploadedFiles,
      fileUrls: newFileUrls
    });
  } 

 
  handleDropdownOpen = () => {
    this.countryCodeFunction();
};

  

  validateForm = () => {
    const errors: { [key: string]: string } = {};

    if (!this.state.userFirstName) {
      errors.userFirstName = 'Please enter your firstname';
    }

    if (!this.state.userLastname) {
      errors.userLastname = 'Please enter your lastname';
    }

    if (!this.state.inputValue) {
      errors.inputValue = 'Please enter email';
    }
    if (!this.state.profileImageFile) {
      this.setState({imgUpload:"Please upload your profile photo"})
    }
    if (!configJSON.emailRegx.test(this.state.inputValue)) {
      errors.inputValue = 'Please enter valid email';
    }

    if (!this.state.roleName) {
      errors.roleName = 'Please enter the role';
    }
    if (!this.state.country) {
      errors.country = 'Please select the country';
    }
    if (!this.state.timeZone) {
      errors.timeZone = "Please select the timezone";
    }
    if (!this.state.bioData) {
      errors.bioData = 'Please select the bio style';
    }
    if (!this.state.text) {
      this.setState({bioField:"Please enter the bio"},()=>{
      })
    }
    if (this.state.uploadedFiles.length === 0) {
      this.setState({fileUpload:"Please upload the document"},()=>{
      })
    }
    else {
      this.UpdationAPI();
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  handleButtonClick = (event: React.FormEvent) => {
    event.preventDefault();
    this.validateForm();
  };

  handleSecondClick = (event: React.FormEvent) => {
    event?.preventDefault();
    this.validateSecondForm();
  };
  handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const bioData = event.target.value as string;
    this.setState({ bioData });
  };


  handleSelectCountry = (event: React.ChangeEvent<{ value: unknown }>) => {
    const country = event.target.value as string;
    this.setState({ country });
  };

  handleSelectTimeZone = (event: React.ChangeEvent<{ value: unknown }>) => {
    const timeZone = event.target.value as string;
    this.setState({ timeZone });
  };

  updationResponse = async () => {
    let formData = new FormData();
    formData.append("account[full_name]", this.state.displayName)
    formData.append("account[user_name]", this.state.nameUser)
    formData.append("account[email]", this.state.inputValue)
    if (this.state.profileImageFile)
      formData.append("account[profile_img]", this.state.profileImageFile,this.state.profileImageFile.name);
    formData.append("account[full_phone_number]", this.state.numberPhone)
    formData.append("account[password]", this.state.passwordChange)
    formData.append("account[name_on_card]", this.state.nameCard)
    formData.append("account[card_number]", this.state.numbCard)
    formData.append("account[exp_month]", this.state.monthExp)
    formData.append("account[exp_year]", this.state.yearExp)
    formData.append("account[cvv_number]", this.state.numbCvv)
    formData.append("account[billing_cvv_number]", this.state.addressName)
    formData.append("account[city]", this.state.cityName)
    formData.append("account[state]", this.state.stateName)
    formData.append("account[zip_code]", this.state.zipNumber)

    this.UpdationSecondApi = await this.apiCall({
      method: configJSON.updateMethod,
      endPoint: `account_block/accounts/${this.state.userId}/update_details`,
      body: formData,
      type: configJSON.updateType
    }); 
  }
  
  handleSelectMonth = (event: React.ChangeEvent<{ value: unknown }>) => {
    const expMonth = event.target.value as string;
    this.setState({ expMonth });
  };

  handleMonth = (event: React.ChangeEvent<{ value: unknown }>) => {
    const   monthExp    = event.target.value as string;
    this.setState({monthExp});
  };

  handleSelectYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    const expYear = event.target.value as string;
    this.setState({ expYear });
  };

  handleYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    const yearExp = event.target.value as string;
    this.setState({ yearExp});
  };

  setErrorMsg = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.replace(/\s/g, '');
    this.setState((prevState) => ({
        ...prevState,
        [field]: value,
    }));
  };

  setZipcode = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.replace(configJSON.zipRegex, '');
    this.setState((prevState) => ({
        ...prevState,
        [field]: value,
    }));
  };

  setspaceMsg = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.replace(/\s/g, '');
    value = value.charAt(0).toUpperCase() + value.slice(1);
    if (/^[a-zA-Z]*$/.test(value) || value === '') {
        this.setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    }
  };

  spaceError = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const inputEvent = event.nativeEvent as InputEvent;
    if (value.trim() === '' && inputEvent?.data === ' ') {
      event.preventDefault(); 
    } else {
      this.setState({ display: value });
    }
};

spaceuserError = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  const inputEvent = event.nativeEvent as InputEvent;
  if (value.trim() === '' && inputEvent?.data === ' ') {
    event.preventDefault(); 
  } else {
    this.setState({ username:value });
  }
};



  UpdationAPI = async () => {
    let formData = new FormData();
    formData.append("account[first_name]", this.state.userFirstName)
    formData.append("account[last_name]", this.state.userLastname)
    formData.append("account[email]", this.state.inputValue)
    if (this.state.profileImageFile)
      formData.append("account[profile_img]", this.state.profileImageFile,this.state.profileImageFile.name);
    formData.append("account[role_name]", this.state.roleName)
    formData.append("account[country]", this.state.country)
    formData.append("account[timezone]", this.state.timeZone)
    formData.append("account[bio]", this.state.text)
    this.state.uploadedFiles.forEach((fileObject) => {
      formData.append("files[]", fileObject.file);
  });
    this.UpdationAPICallId = await this.apiCall({
      method: configJSON.updateMethod,
      endPoint: `account_block/accounts/${this.state.userId}`,
      body: formData,
      type: configJSON.updateType
    }); 
  }

 

  updationHandle = async () => {
    let formData = new FormData();
    formData.append("account[full_name]", this.state.display)
    formData.append("account[user_name]", this.state.username)
    formData.append("account[email]", this.state.inputValue)
    if (this.state.profileImageFile)
      formData.append("account[profile_img]", this.state.profileImageFile,this.state.profileImageFile.name);
    formData.append("account[full_phone_number]", this.state.phoneNumb)
    formData.append("account[country_code]", this.state.selectedCountryId)
    formData.append("account[password]", this.state.passwordChange)
    formData.append("account[name_on_card]", this.state.cardname)
    formData.append("account[card_number]", this.state.cardNumb)
    formData.append("account[exp_month]", this.state.expMonth)
    formData.append("account[exp_year]", this.state.expYear)
    formData.append("account[cvv_number]", this.state.cvvNumb)
    formData.append("account[billing_cvv_number]", this.state.cvvNumber)
    formData.append("account[city]", this.state.city)
    formData.append("account[state]", this.state.cityState)
    formData.append("account[zip_code]", this.state.zipCode)

    this.UpdationSecondApi = await this.apiCall({
      method: configJSON.updateMethod,
      endPoint: `account_block/accounts/${this.state.userId}/update_details`,
      body: formData,
      type: configJSON.updateType
    }); 
  }

  setError = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  

  responseupdateSuccessCall = async (apiRequestCallId: string, responseJson: ResponseData) => {
    if (apiRequestCallId === this.UpdationAPICallId) {
      this.updateSuccessCallBack(responseJson);
    }
  };

  UpdationSecondApiSuccessCall =async (apiRequestCallId: string, responseJson: SecondResponseData) => {
    if (apiRequestCallId === this.UpdationSecondApi) {
      this.updateSecondCallBack(responseJson);
    }
  };

  updateSuccessCallBack =async (responseJson: ResponseData) => {
    this.setState({ isModalOpen: true });
   };

updateSecondCallBack = async (responseJson: SecondResponseData) => {
  this.setState({full_name:responseJson.data.attributes.full_name ,email_id :responseJson.data.attributes.email}, () =>  this.handdleNavigateSetting()) 
 };

 openUserSettings = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };



  subVaildtionCell = (errors: { [key: string]: string }) => {
    if (!isNaN(this.state.cardNumb) && (this.state.cardNumb.length != 16)) {
      errors.cardNumb = 'Please enter valid card number';
    }
    if (!this.state.expMonth) {
      errors.expMonth = 'Please select the  month of expired';
    }
    if (!this.state.expYear) {
      errors.expYear = 'Please select the year of expired';
    }
    if (!this.state.cvvNumb) {
      errors.cvvNumb = 'Please enter the cvv number';
    }
    if (!isNaN(this.state.cvvNumb) && (this.state.cvvNumb.length != 4)) {
      errors.cvvNumb = 'Please enter valid cvv number';
    }
    if (!this.state.cvvNumber) {
      errors.cvvNumber = "Please enter the cvv number";
    }
    if (!isNaN(this.state.cvvNumber) && (this.state.cvvNumber.length != 4)) {
      errors.cvvNumber = 'Please enter valid cvv number';
    }
    if (!this.state.city) {
      errors.city = "Please enter the city";
    }
    if (!this.state.cityState) {
      errors.cityState = "Please enter the state";
    }
    if ((!this.state.zipCode) || (this.state.zipCode.length < 5)) {
      errors.zipCode = "Please enter the valid zipcode";
    }
    else {
      this.updationHandle();
    }


    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  change = (e: ChangeEvent<HTMLTextAreaElement>) => {
    
    if (this.state.isButtonClicked) {
      let newText = e.target.value.endsWith("• ") ? e.target.value : e.target.value.replace(/\u2022 /g, "• ");
      newText = this.state.isBold ? newText.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") : newText;
      newText = this.state.isItalic ? newText.replace(/\*(.*?)\*/g, "<i>$1</i>") : newText;
      this.setState({
        text: newText,
      });
    } else {
      this.setState({
        text: e.target.value,
        bioField:""
      });
    }
  };

 


  checkEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && this.state.isButtonClicked) {
      this.setState((prevState) => {
        const lines = prevState.text.split('\n');
        const lastLine = lines[lines.length - 2];
        const isBulletList = /\u2022 /.test(lastLine);
        const isNumberedList = /^\d+\.\s/.test(lastLine);
  
        let formattedLine = "";
        if (isBulletList) {
          formattedLine = "• ";
        } else if (isNumberedList) {
          formattedLine = `${prevState.numberedListCount}. `;
        }
  
        return {
          text: prevState.text + (prevState.text.trim() === "" ? "" : formattedLine),
          numberedListCount: isNumberedList ? prevState.numberedListCount + 1 : prevState.numberedListCount,
        };
      });
    }
  };
  
  

  handleBulletClick = () => {
    this.setState((prevState) => ({
      isButtonClicked: true,
      text: prevState.text.trim() === "" ? "• " : `${prevState.text}\n• `,
    }));
  };

  

  handleBoldButtonClick = () => {
    this.setState((prevState) => ({
      isBold: !prevState.isBold,
    }));
  };

  handleItalicButtonClick = () => {
    this.setState((prevState) => ({
      isItalic: !prevState.isItalic,
    }));
  };

  handleLinkButtonClick = () => {
    const linkUrl = prompt("Enter the link URL:") || "";
    if (linkUrl) {
      this.setState((prevState) => ({
        linkUrl,
        text: `${prevState.text} ${linkUrl} `,
      }));
    }
  };

  handleNumberedListButtonClick =  () => {
    this.setState((prevState) => ({
      isButtonClicked: true,
      text: prevState.text.trim() === "" ? "1. " : `${prevState.text}\n1. `,
      numberedListCount: 2, 
    }));
  }


  validateSecondForm = () => {
    const errors: { [key: string]: string } = {};

    if (!this.state.display) {
      errors.display = 'Please enter display name';
    }

    if (!this.state.username) {
      errors.username = 'Please enter username';
    }

    if (!configJSON.emailRegx.test(this.state.inputValue)) {
      errors.inputValue = 'Please enter valid email';
    }
    if (!this.state.phoneNumb) {
      errors.phoneNumb = 'Please enter phone number';
    }
      if (!configJSON.phoneRegex.test(this.state.phoneNumb)) {
      errors.phoneNumb = 'Please enter valid phone number';
    }
    if (!this.state.passwordChange) {
      errors.passwordChange = configJSON.paswswordChange;
    }
    if (!configJSON.passwordRegex.test(this.state.passwordChange)) {
      errors.passwordChange = configJSON.validaPassword;
    }
    if (!this.state.cardname) {
      errors.cardname = 'Please enter the card name';
    }
    if (!this.state.cardNumb) {
      errors.cardNumb = "Please enter the card number";
    }

    this.subVaildtionCell(errors)


  };


  validationForm = () => {
    const errors: { [key: string]: string } = {};

    if (!this.state.displayName) {
      errors.displayName = 'Please enter display name';
    }

    if (!this.state.nameUser) {
      errors.nameUser = 'Please enter username';
    }

    if (!this.state.inputValue) {
      errors.inputValue = 'Please enter email';
    }
    if (!configJSON.emailRegx.test(this.state.inputValue)) {
      errors.inputValue = 'Please enter valid email';
    }
    if (!this.state.numberPhone) {
      errors.numberPhone = 'Please enter the phone number';
    }
    if (!this.state.changePassword) {
      errors.passwordChange = configJSON.paswswordChange;
    }
    if (!this.state.nameCard) {
      errors.nameCard = 'Please enter name on card';
    }

    if (!this.state.numbCard) {
      errors.numbCard = 'Please enter cardNumber';
    }
   this.respVaildtionCell(errors)
   
  };

  handleButtonValidation = (event: React.FormEvent) => {
    event?.preventDefault();
    this.validationForm();
  };

  respVaildtionCell = (errors: { [key: string]: string }) => {
    if (!this.state.monthExp) {
      errors.monthExp = 'Please select the expired month';
    }

    if (!this.state.yearExp) {
      errors.yearExp = 'Please select the expired year';
    }
    if (!this.state.numbCvv) {
      errors.numbCvv = 'Please enter name on card';
    }

    if (!this.state.addressName) {
      errors.addressName = 'Please enter the cvv number';
    }
    if (!this.state.cityName) {
      errors.cityName = 'Please enter the city name';
    }

    if (!this.state.stateName) {
      errors.stateName = 'Please enter the state';
    }
    if ((!this.state.zipNumber) || (this.state.zipNumber.length < 5)) {
      errors.zipNumber = 'Please enter the valid zipcode"';
    }
    else{
      this.updationResponse()
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;

 }

 async componentDidMount() {
  let userId = await getStorageData("userId")
  this.setState({userId:userId})
}




  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }

  handdleNavigateSetting= () =>{
    const msg: Message = new Message(
      getName(MessageEnum.SettingsScreenNavigationPropsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.sendEmailIdProps),this.state.email_id);
    msg.addData(getName(MessageEnum.sendNameIdProps),this.state.full_name);
    this.send(msg);
  }
  // Customizable Area End

}
