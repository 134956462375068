import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    styled,
    Hidden,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from "@material-ui/core";
import { blackArrow, quilaImage, mail, points, numbers, fontstyle, bold, upload, content, featured, helpcircle, visa, eye, pdf, checkbox, header, filemp, fig, remove } from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Country } from "./UserProfileBasicController";
import LinearProgress from '@material-ui/core/LinearProgress';
// Customizable Area End
import UserProfileBasicController, {
    Props, configJSON
} from "./UserProfileBasicController";

export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    renderHeader = () => {
        return (
            <Header >
                <BackIcon src={header} />
                <QualImage src={quilaImage} alt="quila" />
            </Header>
        )
    }

    renderUserInfo = () => {
        return (
            <>
                <SubmitContainer>
                    <UserGrid item xs={12} sm={6} md={6} lg={6} >
                        <UserTypography>{configJSON.userInfo}</UserTypography>
                        <Personalypography>{configJSON.personalInfo}</Personalypography>
                        <InfoText>{configJSON.updateInfo}</InfoText>
                    </UserGrid>
                    <Box>
                        <ButtonContainer>
                            <CancelButton type="submit" variant="contained">
                                {configJSON.cancel}
                            </CancelButton>
                            <SaveButton type="button" variant="outlined" onClick={(event) => this.handleButtonClick(event)} >
                                {configJSON.save}
                            </SaveButton>
                        </ButtonContainer>
                    </Box>
                </SubmitContainer>
            </>
        )
    }

    renderForm = () => {
        const { errors } = this.state;
        const countries: Country[] = require('country-state-city').Country.getAllCountries();
        return (
            <FormContainer>
                <form onSubmit={(event) => this.handleButtonClick(event)} data-test-id="submitTestID">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2} md={2} lg={3}>
                            <TextName>{configJSON.userName}</TextName>
                            <FirstName>{configJSON.firstName}</FirstName>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <TextField
                                data-test-id="firstNameID"
                                error={!!errors.userFirstName}
                                variant="outlined"
                                className="input_field_config"
                                value={this.state.userFirstName}
                                fullWidth
                                onChange={this.setspaceMsg("userFirstName")}
                                InputLabelProps={{
                                    disableAnimation: true,
                                    shrink: false,
                                    style: {
                                        color: "#757676",
                                        opacity: 1
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: "#757676",
                                        border: !!errors.userFirstName ? "1px solid red" : "",
                                        backgroundColor: !!errors.userFirstName ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                        borderRadius: "7px"
                                    }
                                }}
                                helperText={errors.userFirstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Hidden mdUp>
                                <LastName>{configJSON.lastName}</LastName>
                            </Hidden>
                            <TextField
                                data-test-id="lastNameID"
                                error={!!errors.userLastname}
                                variant="outlined"
                                value={this.state.userLastname}
                                fullWidth
                                onChange={this.setspaceMsg("userLastname")}
                                InputLabelProps={{
                                    disableAnimation: true,
                                    shrink: false,
                                    style: {
                                        color: "#757676",
                                        opacity: 1
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: "#757676",
                                        border: !!errors.userLastname ? "1px solid red" : "",
                                        backgroundColor: !!errors.userLastname ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                        borderRadius: "7px"
                                    }
                                }}
                                helperText={errors.userLastname}
                            />
                        </Grid>
                        <LineGrid />
                        <Grid item xs={12} sm={2} md={2} lg={3} >
                            <NameText >{configJSON.emailAddress}</NameText>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <EmalTextField
                                data-test-id="emailInputID"
                                error={!!errors.inputValue}
                                variant="outlined"
                                value={this.state.inputValue}
                                fullWidth
                                onChange={this.setErrorMsg("inputValue")}
                                InputLabelProps={{
                                    disableAnimation: true,
                                    shrink: false,
                                    style: {
                                        color: "#757676",
                                        opacity: 1
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: "#757676",
                                        border: !!errors.inputValue ? "1px solid red" : "",
                                        backgroundColor: !!errors.inputValue ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                        borderRadius: "7px",
                                    },
                                    startAdornment: <img src={mail} />

                                }}
                                helperText={errors.inputValue}
                            />
                        </Grid>
                        <LineGrid />
                        <Grid item xs={12} sm={2} md={2} lg={3} >
                            <Grid style={{ display: 'flex' }}>
                                <NameText>{configJSON.yourPhoto}</NameText>
                                <QuesrionCircle src={helpcircle} alt="" />
                            </Grid>
                            <SnippetText>This will be displayed on your profile.</SnippetText>
                                                    </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ display: 'flex' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <AvatarImg src={this.state.profileImg} className="avatarImg" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={10} lg={10} >
                                    <FeatureImg src={featured} alt=""
                                        onClick={this.handleUploadClick}
                                        data-test-id="handleclicktestID" />
                                    <input
                                        data-test-id="imageSeletTestId"
                                        accept=".png, .svg, .jpeg, .jpg"
                                        type="file"
                                        ref={this.imageInputRef}
                                        onChange={this.handleImageChange}
                                        style={{ display: "none" }}
                                    />
                                    <ErrorText>{this.state.error}</ErrorText>
                                     <ErrorText >{this.state.imgUpload}</ErrorText>

                                </Grid>
                            </Grid>
                        </Grid>
                        <LineGrid />
                        <Grid item xs={12} sm={2} md={2} lg={3} >
                            <NameText>{configJSON.role}</NameText>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                data-test-id="roleInputID"
                                error={!!errors.roleName}
                                required={!!errors.roleName ? true : false}
                                variant="outlined"
                                value={this.state.roleName}
                                fullWidth
                                onChange={this.setErrorMsg("roleName")}
                                InputLabelProps={{
                                    disableAnimation: true,
                                    shrink: false,
                                    style: {
                                        color: "#757676",
                                        opacity: 1
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: "#757676",
                                        border: !!errors.roleName ? "1px solid red" : "",
                                        backgroundColor: !!errors.roleName ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                        borderRadius: "7px"
                                    }
                                }}
                                helperText={errors.roleName}
                            />
                        </Grid>
                        <LineGrid />
                        <Grid item xs={12} sm={2} md={2} lg={3} >
                            <NameText>{configJSON.country}</NameText>
                        </Grid>
                        {this.countrySelect()}
                        <LineGrid />
                        <Grid item xs={12} sm={2} md={2} lg={3} >
                            <Grid style={{ display: 'flex' }}>
                                <NameText >{configJSON.timeZone}</NameText>
                                <QuesrionCircle src={helpcircle} alt="" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <BioBox>
                                <Select
                                    data-test-id="timezoneTestID"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.timeZone}
                                    IconComponent={ExpandMoreIcon}
                                    onChange={this.handleSelectTimeZone}
                                    error={!!errors.timeZone}
                                    MenuProps={{ style: { marginTop: "50px" } }}
                                    style={{
                                        borderRadius: "8px",
                                        width: '100%',
                                        backgroundColor: !!errors.timeZone ? "rgba(229, 57, 53, 0.15)" : "#fff"
                                    }}
                                >
                                    {countries.map((country) => (
                                        <MenuItem key={country.isoCode} value={country.name}>
                                            {country.timezones[0].tzName}({country.timezones[0].abbreviation}) {country.timezones[0].gmtOffsetName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.timeZone && <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.timeZone}</Typography>}
                            </BioBox>
                        </Grid>
                        <LineGrid ></LineGrid>
                        <Grid item xs={12} sm={2} md={2} lg={3} >
                            <NameText>Bio</NameText>
                            <SnippetText>Write a short introduction.</SnippetText>
                        </Grid>
                        {this.renderBioInfo()}
                        <LineGrid />
                        {this.uplaodDoc()}
                        <LineGrid />
                        <FormButtonContainer>
                            <CancelButton type="submit" variant="contained">
                                {configJSON.cancel}
                            </CancelButton>
                            <SaveButton type="submit" variant="outlined" >
                                {configJSON.save}
                            </SaveButton>
                        </FormButtonContainer>
                    </Grid>
                </form>
            </FormContainer>
        )
    };


    renderBioInfo =()=>{
        const { errors } = this.state;
        const textStyle: React.CSSProperties = {
            fontWeight: this.state.isBold ? 'bold' : 'normal',
            fontStyle: this.state.isItalic ? 'italic' : 'normal',
            width: '100%',
            height: '200px',
            borderRadius: '10px',
            marginTop: "5%",
            outline: "none",
            border: this.state.bioField ? "1px solid red" : "1px solid #D0D5DD",
            padding: '10px',
            backgroundColor: this.state.bioField ? "rgba(229, 57, 53, 0.15)" : "#fff"
        };
     return(
        <Grid item xs={12} sm={6}>
        <BioGrid item xs={12}>
            <BioBoxSelect item xs={12} sm={6}>
                <Select
                    data-test-id="bioTestID"
                    variant="outlined"
                    value={this.state.bioData}
                    IconComponent={ExpandMoreIcon}
                    onChange={this.handleSelectChange}
                    error={!!errors.bioData}
                    MenuProps={{ style: { marginTop: "50px" } }}
                    style={{
                        borderRadius: "8px",
                        width: '100%',
                        backgroundColor: !!errors.bioData ? "rgba(229, 57, 53, 0.15)" : "#fff"
                    }}
                >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="option1">Regular</MenuItem>
                </Select>
                {errors.bioData && <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.bioData}</Typography>}
            </BioBoxSelect>
            <FontContainer item xs={12} sm={6}>
                <Button onClick={this.handleBoldButtonClick} data-test-id="boldTexttestID">
                    <img src={bold} />
                </Button>
                <Button onClick={this.handleItalicButtonClick} data-test-id="italicButtonTestId">
                    <img src={fontstyle} />
                </Button>
                <Button onClick={this.handleLinkButtonClick} data-test-id="linktestID" >
                    <FontImg src={upload} />
                </Button>
                <Button onClick={this.handleBulletClick} data-test-id="bullettestID">
                    <FontImg src={points} />
                </Button>
                <Button onClick={this.handleNumberedListButtonClick} data-test-id="numberedtestID">
                    <FontImg src={numbers} />
                </Button>
            </FontContainer>
        </BioGrid>
        <textarea
            data-test-id="bioTextTestId"
            onChange={this.change}
            onKeyUp={this.checkEnter}
            value={this.state.text}
            style={textStyle}
        />
        {this.state.bioField && <ErrorText >{this.state.bioField}</ErrorText>}
    </Grid>
     )
    }


    countrySelect = () => {
        const { errors } = this.state;
        const countries: Country[] = require('country-state-city').Country.getAllCountries();
        return (
            <Grid item xs={12} sm={6}>
                <BioBox>
                    <Select
                        value={this.state.country}
                        displayEmpty
                        data-test-id="countrytestID"
                        variant="outlined"
                        fullWidth
                        IconComponent={ExpandMoreIcon}
                        onChange={this.handleSelectCountry}
                        error={!!errors.country}
                        MenuProps={{ style: { marginTop: "50px" } }}
                        style={{
                            borderRadius: "8px",
                            width: '100%',
                            backgroundColor: !!errors.country ? "rgba(229, 57, 53, 0.15)" : "#fff"
                        }}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country.isoCode} value={country.name}>
                                {country.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.country && <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.country}</Typography>}
                </BioBox>
            </Grid>
        )
    }

    uplaodDoc = () => {
        const fileTypeIcons: { [key: string]: string } = {
            'application/pdf': pdf,
            'video/mp4': filemp,
        };
        return (
            <>
                <Grid item xs={12} sm={2} md={2} lg={3} >
                    <Grid style={{ display: 'flex' }}>
                        <NameText>Portfolio projects</NameText>
                        <QuesrionCircle src={helpcircle} alt="" />
                    </Grid>
                    <SnippetText>Share a few snippets of your work.</SnippetText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ImgContainer onClick={this.handleIconClick} data-test-id="uploadImagetestId">
                        <img src={content} style={{ width: '100%' }} />
                        <input
                            data-test-id="fileChangetestId"
                            type="file"
                            accept=".pdf, .mp4, .fig"
                            multiple
                            ref={this.fileInputRef}
                            style={{ display: 'none' }}
                            onChange={this.handleFileChange}
                        />
                    </ImgContainer>
                    <ErrorText>{this.state.error}</ErrorText>
                    {this.state.uploadedFiles && <ErrorText>{this.state.fileUpload}</ErrorText>}
                    {this.state.fileUrls.map((url, index) => {
                        const fileType = this.state.uploadedFiles[index].file.type;
                        const iconSrc: string = fileTypeIcons[fileType] || '';
                        const isUploadCompleted = this.state.uploadProgress[index] === 100;
                        return (
                            <BioContainer key={index}>
                                <Box>
                                    {iconSrc ? <DocContainer src={iconSrc} alt="Left Image" /> : <DocContainer src={fig} alt="Left Image" />}
                                </Box>
                                <Box className="fileBox">
                                    <Box className="fileDetails">
                                        <DocUploadText >
                                            {this.state.uploadedFiles[index].file.name}
                                                                                        {isUploadCompleted ? (
                                                <img src={checkbox} alt="remove" />
                                            ) :
                                                <img src={remove} alt="Right Image" onClick={() => this.handleDelete(index)} data-test-id="deletetestId" />}
                                        </DocUploadText>
                                        <SizeText >
                                            {this.formatFileSize(this.state.uploadedFiles[index].file.size)}
                                        </SizeText>

                                    </Box>
                                    <Box className="fileProgress">
                                        <Progressline value={this.state.uploadProgress[index] || 0} variant={'determinate'} />
                                        <PercentText>{Math.floor(this.state.uploadProgress[index] || 0)}%</PercentText>
                                    </Box>
                                </Box>
                            </BioContainer>)
                    })}
                </Grid>
            </>
        )
    }

    renderMenuList = () => {
        return (
            <>
                  <TextField
                    id="standard-basic"
                    label={this.state.stateName ? "" : "State"}
                    className="userProfileinput"
                    fullWidth
                    variant="outlined"
                    data-test-id="stateselecttesttID"
                    error={!!this.state.errors.stateName}
                    value={this.state.stateName}
                    onChange={this.setError("stateName")}
                    InputProps={{
                        style: {
                            color: "#757676",
                            backgroundColor: !!this.state.errors.stateName ? "rgba(229, 57, 53, 0.15)" : "#fff",
                            borderRadius: "6px",
                            width: "100%",
                            height: "55px",
                        },
                        startAdornment: <></>,
                    }}
                    helperText={this.state.errors.stateName}
                    FormHelperTextProps={{
                        style:{margin:"0px"}
                    }}
                />
                </>
              
        )
    }


    renderUserProfile = () => {
        return (
            <Box className="userProfile">
                <Box className="iconSection">
                    <img src={quilaImage} alt="" className="topIcon" />
                </Box>
                <Box className="userInfoConfirmationContainer">
                    <Box className="userInfoConfirmationLeft">
                        <Typography className="userInfoConfirmationLeftHeading">
                            {configJSON.userInfo}
                        </Typography>
                        <Typography className="userInfoConfirmationLeftSubHeading">
                            {configJSON.updateInfo}
                        </Typography>
                    </Box>
                    <Box className="userInfoConfirmationRight">
                        <Button className="userInfoConfirmationCancelBtn">{configJSON.cancel}</Button>
                        <Button className="userInfoConfirmationSaveBtn" onClick={(event) => this.handleSecondClick(event)} data-test-id="saveTestID">{configJSON.save} </Button>
                    </Box>
                </Box>
                <Box className="userInfoItem userInfoItem1">
                    <Typography className="userInfoLabel">
                        {configJSON.display}
                    </Typography>
                    <TextField
                        data-test-id="displayNameID"
                        error={!!this.state.errors.display}
                        variant="outlined"
                        value={this.state.display}
                        onChange={this.spaceError}
                        InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                            style: {
                                color: "#757676",
                                opacity: 1
                            }
                        }}
                        InputProps={{
                            style: {
                                color: "#757676",
                                border: !!this.state.errors.display ? "1px solid red" : "",
                                backgroundColor: !!this.state.errors.display ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                borderRadius: "6px",
                                width: "512px",
                                height: "55px",
                            }
                        }}
                        helperText={this.state.errors.display}
                    />
                </Box>
                <Box className="userInfoItem userInfoItem2">
                    <Typography className="userInfoLabel">
                        {configJSON.user}
                    </Typography>
                    <TextField
                        data-test-id="userNameID"
                        error={!!this.state.errors.username}
                        variant="outlined"
                        value={this.state.username}
                        onChange={this.spaceuserError}
                        InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                            style: {
                                color: "#757676",
                                opacity: 1
                            }
                        }}
                        InputProps={{
                            style: {
                                color: "#757676",
                                border: !!this.state.errors.username ? "1px solid red" : "",
                                backgroundColor: !!this.state.errors.username ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                borderRadius: "6px",
                                width: "512px",
                                height: "55px",
                            }
                        }}
                        helperText={this.state.errors.username}
                    />
                </Box>
                <Box className="userInfoItem userInfoItem2">
                    <Typography className="userInfoLabel">
                        {configJSON.emailAddress}
                    </Typography>
                    <EmalTextField
                        data-test-id="emailID"
                        error={!!this.state.errors.inputValue}
                        variant="outlined"
                        value={this.state.inputValue}
                        onChange={this.setErrorMsg("inputValue")}
                        InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                            style: {
                                color: "#757676",
                                opacity: 1
                            }
                        }}
                        InputProps={{
                            style: {
                                color: "#757676",
                                border: !!this.state.errors.inputValue ? "1px solid red" : "",
                                backgroundColor: !!this.state.errors.inputValue ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                borderRadius: "6px",
                                width: "512px",
                                height: "55px",
                            },
                            startAdornment: <img src={mail} />
                        }}
                        helperText={this.state.errors.inputValue}
                    />
                </Box>
                <Box className="userInfoItem userInfoItem2 userInfoItem3">
                    <Box className="userProfilePickerLabelContainer">
                        <Typography className="userInfoLabel">
                            {configJSON.yourPhoto}
                            <HelpCircle src={helpcircle} alt="" />
                        </Typography>
                        <Typography className="userProfileSubLabel">
                            {configJSON.displayProfile}
                        </Typography>
                    </Box>
                    <Box className="userProfilePicker">
                        <Box className="avatarImgContainer">

                            <img src={this.state.profileImg} alt="" className="avatarImg" />
                        </Box>
                        <Box>
                        <img
                            src={featured}
                            alt=""
                            className="avatarImgPicker"
                            onClick={this.handleUploadClick}
                        />
                        <input
                            accept=".png, .svg, .jpeg, .jpg"
                            type="file"
                            ref={this.imageInputRef}
                            onChange={this.handleImageChange}
                            className="avatarSelectioninput"
                        />
                        <ErrorText>{this.state.error}</ErrorText>
                        {this.state.imgUpload && <ErrorText >{this.state.imgUpload}</ErrorText>}
                        </Box>
                    </Box>
                </Box>
                <Box className="userInfoItem userInfoItem2">
                    <Typography className="userInfoLabel">
                        {configJSON.phoneNumber}
                    </Typography>
                    <Box className="phoneNumberContainer">
                        <>
                            <Select 
                                data-test-id="countryCodetestId"
                                IconComponent={(props) => (
                                    <>
                                        {this.state.selectedCountryId ? null : <ExpandMoreIcon {...props} />}
                                    </>
                                )}
                                MenuProps={{ style: { marginTop: "50px" } }}
                                style={{
                                    borderRadius: "8px",
                                    width:"17%"
                                }}
                                value={this.state.selectedCountryId}
                                onChange={this.handleCountryChange}
                                onOpen={this.handleDropdownOpen}
                                variant="outlined"
                            >
                                {this.state.countryCodeFilterList.map((item: { id: string, attributes: { country_code: string, emoji_flag: string } }) => (
                                    <MenuItem key={item.id} value={item.id} style={{width:"100%"}}>
                                        {item.attributes.country_code}
                                    </MenuItem>

                                ))}
                            </Select>
                        </>
                        <TextField
                            data-test-id="phoneNumberID"
                            error={!!this.state.errors.phoneNumb}
                            variant="outlined"
                            value={this.state.phoneNumb}
                            onChange={this.setErrorMsg("phoneNumb")}
                            InputLabelProps={{
                                disableAnimation: true,
                                shrink: false,
                                style: {
                                    color: "#757676",
                                    opacity: 1
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: "#757676",
                                    border: !!this.state.errors.phoneNumb ? "1px solid red" : "",
                                    backgroundColor: !!this.state.errors.phoneNumb ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                    borderRadius: "6px",
                                    width: "410px",
                                    height: "55px",
                                }
                            }}
                            helperText={this.state.errors.phoneNumb}
                        />
                    </Box>
                </Box>
                <Box className="userInfoItem userInfoItem2">
                    <Typography className="userInfoLabel">
                        {configJSON.btnTextChangePassword}
                    </Typography>
                    <TextField
                        data-test-id="passwordID"
                        error={!!this.state.errors.passwordChange}
                        variant="outlined"
                        value={this.state.passwordChange}
                        onChange={this.setErrorMsg("passwordChange")}
                        InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                            style: {
                                color: "#757676",
                                opacity: 1
                            }
                        }}
                        InputProps={{
                            style: {
                                color: "#757676",
                                border: !!this.state.errors.passwordChange ? "1px solid red" : "",
                                backgroundColor: !!this.state.errors.passwordChange ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                borderRadius: "6px",
                                width: "512px",
                                height: "55px",
                            }
                        }}
                        helperText={this.state.errors.passwordChange}
                    />
                </Box>
                <Box className="userInfoItem2">
                    <Typography className="creditCardInfoText">
                        Credit Card Info
                    </Typography>
                </Box>
                <Box className="userInfoItem userInfoItem2">
                    <Typography className="userInfoLabel">
                        {configJSON.cardName}
                    </Typography>
                    <TextField
                        data-test-id="cardNameID"
                        error={!!this.state.errors.cardname}
                        variant="outlined"
                        value={this.state.cardname}
                        onChange={this.setErrorMsg("cardname")}
                        InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                            style: {
                                color: "#757676",
                                opacity: 1
                            }
                        }}
                        InputProps={{
                            style: {
                                color: "#757676",
                                border: !!this.state.errors.cardname ? "1px solid red" : "",
                                backgroundColor: !!this.state.errors.cardname ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                borderRadius: "6px",
                                width: "512px",
                                height: "55px",
                            }
                        }}
                        helperText={this.state.errors.cardname} />
                </Box>
                <Box className="userInfoItem userInfoItem2">
                    <Typography className="userInfoLabel">
                        {configJSON.cardNumber}
                    </Typography>
                    {this.rendercardDetail()}
                </Box>
                <Box className="userInfoItem userInfoItem2 expireDateContainer">
                    <Typography className="userInfoLabel">
                        {configJSON.month}
                    </Typography>
                    <Box className="expireDateSelectContainer">
                        {this.renderMonthSelect()}
                        <Typography className="expireDateSelectLabel">
                            {configJSON.year}
                        </Typography>
                        {this.renderListMenu()}
                    </Box>
                </Box>
                {this.renderBilling()}
                <Box className="userInfoItem2 userInfoSaveContainer">
                    <Box className="userInfoSaveBtnContainer">
                        <Button className="userInfoCancelBtn">{configJSON.cancel}</Button>
                        <Button data-test-id="sectionId" className="userInfoSaveBtn" onClick={(event) => this.handleSecondClick(event)} >{configJSON.save}</Button>
                    </Box>
                </Box>
            </Box>
        );
    };

    rendercardDetail = () => {
        return (
            <TextField
                data-test-id="cardNumbID"
                error={!!this.state.errors.cardNumb}
                variant="outlined"
                value={this.state.cardNumb}
                onChange={this.setErrorMsg("cardNumb")}
                InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    style: {
                        color: "#757676",
                        opacity: 1
                    }
                }}
                InputProps={{
                    style: {
                        color: "#757676",
                        border: !!this.state.errors.cardNumb ? "1px solid red" : "",
                        backgroundColor: !!this.state.errors.cardNumb ? "rgba(229, 57, 53, 0.15)" : "#fff",
                        borderRadius: "6px",
                        width: "512px",
                        height: "55px",
                    },
                    endAdornment: <img src={visa} />,

                }}
                helperText={this.state.errors.cardNumb} />
        )
    }


    renderMonthSelect = () => {
        return (
            <FormControl variant="outlined" fullWidth>
                <BioBox>
                    <Select
                        data-test-id="monthtestId"
                        variant="outlined"
                        fullWidth
                        value={this.state.expMonth}
                        IconComponent={ExpandMoreIcon}
                        onChange={this.handleSelectMonth}
                        error={!!this.state.errors.expMonth}
                        MenuProps={{ style: { marginTop: "50px" } }}
                        style={{
                            borderRadius: "8px",
                            width: '100%',
                            backgroundColor: !!this.state.errors.expMonth ? "rgba(229, 57, 53, 0.15)" : "#fff"
                        }}
                    >
                        {this.state.months.map((month, index) => (
                            <MenuItem key={index} value={month}>
                                {month}
                            </MenuItem>
                        ))}
                    </Select>
                    {this.state.errors.expMonth && <Typography style={{ color: 'red', fontSize: '10px' }}>{this.state.errors.expMonth}</Typography>}
                </BioBox>
            </FormControl>
        )
    }

    renderBilling = () => {
        return (
            <Box>
                <Box className="userInfoItem userInfoItem2">
                    <Typography className="userInfoLabel">
                        {configJSON.cvvNumber}
                    </Typography>
                    <TextField
                        data-test-id="cvvnumbertestID"
                        error={!!this.state.errors.cvvNumb}
                        variant="outlined"
                        value={this.state.cvvNumb}
                        onChange={this.setErrorMsg("cvvNumb")}
                        InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                            style: {
                                color: "#757676",
                                opacity: 1
                            }
                        }}
                        InputProps={{
                            style: {
                                color: "#757676",
                                border: !!this.state.errors.cvvNumb ? "1px solid red" : "",
                                backgroundColor: !!this.state.errors.cvvNumb ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                borderRadius: "6px",
                                width: "512px",
                                height: "55px",
                            },

                        }}
                        helperText={this.state.errors.cvvNumb} />
                </Box>
                <Box className="userInfoItem2">
                    <Typography className="creditCardInfoText">
                        {configJSON.billing}
                    </Typography>
                </Box>
                <Box className="userInfoItem2">
                    <Box className="billingCvvCheckBoxContainer">
                        <FormControlLabel
                        data-test-id="formBoxTestId"
                            control={<Checkbox
                            data-test-id="checkBoxTestId"
                            checked={this.state.checked}
                            onChange={this.handleCheckboxChange} />}
                            label={configJSON.billingAccount}
                        />
                    </Box>
                    <Box className="userInfoItem">
                        <Typography className="userInfoLabel">
                            {configJSON.cvvNumber}
                        </Typography>
                        <TextField
                            data-test-id="cvvtestId"
                            error={!!this.state.errors.cvvNumber}
                            variant="outlined"
                            value={this.state.checked?this.state.cvvNumb:this.state.cvvNumber}
                            onChange={this.setErrorMsg("cvvNumber")}
                            InputLabelProps={{
                                disableAnimation: true,
                                shrink: false,
                                style: {
                                    color: "#757676",
                                    opacity: 1
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: "#757676",
                                    border: !!this.state.errors.cvvNumber ? "1px solid red" : "",
                                    backgroundColor: !!this.state.errors.cvvNumber ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                    borderRadius: "8px",
                                    width: "512px",
                                    height: "55px",
                                }
                            }}
                            helperText={this.state.errors.cvvNumber}
                        />
                    </Box>
                </Box>
                <Box className="userInfoItem userInfoItem2">
                    <Typography className="userInfoLabel">
                        {configJSON.city}
                    </Typography>
                    <TextField
                        data-test-id="cityNametestID"
                        error={!!this.state.errors.city}
                        variant="outlined"
                        value={this.state.city}
                        onChange={this.setErrorMsg("city")}
                        InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                            style: {
                                color: "#757676",
                                opacity: 1
                            }
                        }}
                        InputProps={{
                            style: {
                                color: "#757676",
                                border: !!this.state.errors.city ? "1px solid red" : "",
                                backgroundColor: !!this.state.errors.city ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                borderRadius: "8px",
                                width: "512px",
                                height: "55px",
                            }
                        }}
                        helperText={this.state.errors.city} />
                </Box>
                <Box className="userInfoItem userInfoItem2 stateZipContainer">
                    <Typography className="userInfoLabel">
                        State
                    </Typography>
                    <Box className="expireDateInputContainer">
                        <TextField
                            data-test-id="citytestID"
                            error={!!this.state.errors.cityState}
                            variant="outlined"
                            value={this.state.cityState}
                            onChange={this.setErrorMsg("cityState")}
                            InputLabelProps={{
                                disableAnimation: true,
                                shrink: false,
                                style: {
                                    color: "#757676",
                                    opacity: 1
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: "#757676",
                                    border: !!this.state.errors.cityState ? "1px solid red" : "",
                                    backgroundColor: !!this.state.errors.cityState ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                    borderRadius: "8px",
                                    width: "258px",
                                    height: "55px",
                                }
                            }}
                            helperText={this.state.errors.cityState}
                        />
                        <Box className="expireYearInputContainer">
                            <Typography
                                className="userInfoLabel userInfoLabelExpireDate">
                                Zip Code
                            </Typography>
                            <TextField
                                data-test-id="ziptestID"
                                error={!!this.state.errors.zipCode}
                                variant="outlined"
                                value={this.state.zipCode}
                                onChange={this.setZipcode("zipCode")}
                                InputLabelProps={{
                                    disableAnimation: true,
                                    shrink: false,
                                    style: {
                                        color: "#757676",
                                        opacity: 1
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: "#757676",
                                        border: !!this.state.errors.zipCode ? "1px solid red" : "",
                                        backgroundColor: !!this.state.errors.zipCode ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                        borderRadius: "8px",
                                        width: "258px",
                                        height: "55px",
                                    }
                                }}
                                helperText={this.state.errors.zipCode}
                                FormHelperTextProps={{
                                    style:{margin:"0px"}
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box className="expMonthYearContainer">
                    <Box className=" userInfoItem userInfoItem2">
                        <Typography className="userInfoLabel userInfoLabelExpireDate">
                            State
                        </Typography>
                        <TextField
                            variant="outlined"
                            className="userInfoInput "
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                },
                            }}
                        />
                    </Box>
                    <Box className=" userInfoItem userInfoItem2">
                        <Typography className="userInfoTypography userInfoLabelExpireDate">
                            Zip Code
                        </Typography>
                        <TextField
                            variant="outlined"
                            className="userInfoInput "
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                },
                            }}
                        />
                    </Box>
                </Box>
                <Box className="userInfoItem2 talentAccountContainer">
                    <Typography className="talentAccountHeading">
                        {configJSON.tanentAccount}
                    </Typography>
                    <Box className="talentAccountBtnContainer">
                        <Button className="talentAccountBtn">{configJSON.no}</Button>
                        <Button className="talentAccountBtn talentAccountBtnActive">
                            {configJSON.yes}
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    renderListMenu = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => (currentYear + index).toString());
        return (
            <FormControl variant="outlined" fullWidth>
                <BioBox>
                    <Select
                        data-test-id="yeartestId"
                        variant="outlined"
                        fullWidth
                        value={this.state.expYear}
                        IconComponent={ExpandMoreIcon}
                        onChange={this.handleSelectYear}
                        error={!!this.state.errors.expYear}
                        MenuProps={{ style: { marginTop: "50px" } }}
                        style={{
                            borderRadius: "8px",
                            width: '100%',
                            backgroundColor: !!this.state.errors.expYear ? "rgba(229, 57, 53, 0.15)" : "#fff"
                        }}
                    >
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                    {this.state.errors.expYear && <Typography style={{ color: 'red', fontSize: '10px' }}>{this.state.errors.expYear}</Typography>}
                </BioBox>
            </FormControl>
        )
    }

    renderTextInputs = () => {
        return (
            <>
                <TextField
                    label={this.state.displayName ? "" : configJSON.display}
                    className="userProfileinput"
                    fullWidth
                    variant="outlined"
                    data-test-id="displaytestID"
                    error={!!this.state.errors.displayName}
                    value={this.state.displayName}
                    onChange={this.setErrorMsg("displayName")}
                    InputProps={{
                        style: {
                            color: "#757676",
                            backgroundColor: !!this.state.errors.displayName ? "rgba(229, 57, 53, 0.15)" : "#fff",
                            borderRadius: "6px",
                            width: "100%",
                            height: "55px",
                        },
                        startAdornment: <></>,

                    }}

                    helperText={this.state.errors.displayName}
                />
                <TextField
                    label={this.state.nameUser ? "" : configJSON.user}
                    className="userProfileinput"
                    fullWidth
                    variant="outlined"
                    data-test-id="usertestID"
                    error={!!this.state.errors.nameUser}
                    value={this.state.nameUser}
                    onChange={this.setErrorMsg("nameUser")}
                    InputProps={{
                        style: {
                            color: "#757676",
                            backgroundColor: !!this.state.errors.nameUser ? "rgba(229, 57, 53, 0.15)" : "#fff",
                            borderRadius: "6px",
                            width: "100%",
                            height: "55px",
                        },
                        startAdornment: <></>,
                    }}
                    helperText={this.state.errors.nameUser}
                />
                <TextField
                    label={this.state.inputValue ? "" : configJSON.inputValue}
                    className="userProfileinput"
                    fullWidth
                    variant="outlined"
                    data-test-id="emailtestID"
                    error={!!this.state.errors.inputValue}
                    value={this.state.inputValue}
                    onChange={this.setErrorMsg("inputValue")}
                    InputProps={{
                        style: {
                            color: "#757676",
                            backgroundColor: !!this.state.errors.inputValue ? "rgba(229, 57, 53, 0.15)" : "#fff",
                            borderRadius: "6px",
                            width: "100%",
                            height: "55px",
                        },
                        startAdornment: <img src={mail} />,

                    }}
                    helperText={this.state.errors.inputValue}
                />
                <TextField
                    id="standard-basic"
                    label={this.state.numberPhone ? "" : configJSON.phoneNumber}
                    className="userProfileinput"
                    fullWidth
                    variant="outlined"
                    data-test-id="phonetestID"
                    error={!!this.state.errors.numberPhone}
                    value={this.state.numberPhone}
                    onChange={this.setErrorMsg("numberPhone")}
                    InputProps={{
                        style: {
                            color: "#757676",
                            backgroundColor: !!this.state.errors.numberPhone ? "rgba(229, 57, 53, 0.15)" : "#fff",
                            borderRadius: "6px",
                            width: "100%",
                            height: "55px",
                        },
                        startAdornment: <></>,
                    }}
                    helperText={this.state.errors.numberPhone}
                />
                <TextField
                    label={this.state.changePassword ? "" : configJSON.btnTextChangePassword}
                    className="userProfileinput"
                    fullWidth
                    variant="outlined"
                    data-test-id="passwordtestID"
                    error={!!this.state.errors.changePassword}
                    value={this.state.changePassword}
                    onChange={this.setErrorMsg("changePassword")}
                    InputProps={{
                        style: {
                            color: "#757676",
                            backgroundColor: !!this.state.errors.changePassword ? "rgba(229, 57, 53, 0.15)" : "#fff",
                            borderRadius: "6px",
                            width: "100%",
                            height: "55px",
                        },
                        endAdornment: <img src={eye} />,
                        startAdornment: <></>,
                    }}
                    helperText={this.state.errors.changePassword}
                />
            </>
        )
    }


    renderUserProfileResponsive = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => (currentYear + index).toString());
        return (
            <Box className="userProfileResponsive">
                <Box className="backArrowContainer">
                    <img src={blackArrow} alt="" />
                </Box>
                <Box className="profilePhotoContainer">
                    <Typography className="profilePhotoHeading">{configJSON.editProfile}</Typography>
                    <Box className="profilePhotoWrapper">
                        <AvatarImg src={this.state.profileImg} alt="" />
                    </Box>
                    <CurserTypography className="profilePhotoSubHeading" onClick={this.handleUploadClick} >
                        {configJSON.editPicture}
                        <input data-test-id="imageSeletTestId"
                            accept=".png, .svg, .jpeg, .jpg"
                            type="file"
                            ref={this.imageInputRef}
                            onChange={this.handleImageChange}
                            style={{ display: "none" }}
                        />
                         <ErrorText>{this.state.error}</ErrorText>
                        {!this.state.error && <ErrorText >{this.state.imgUpload}</ErrorText>}
                    </CurserTypography>
                </Box>
                <Typography className="userProfileheading">{configJSON.userInfo}</Typography>
                <Box>
                    <form onSubmit={(event) => this.handleButtonValidation(event)}
                        data-test-id="buttonTestID"
                        noValidate
                        autoComplete="off"
                        className="userProfileFormContainer"
                    >
                        {this.renderTextInputs()}
                        <Typography className="userProfileheading">
                            Credit Card Info
                        </Typography>
                        <TextField
                            id="standard-basic"
                            label={this.state.nameCard ? "" : configJSON.cardName}
                            className="userProfileinput"
                            fullWidth
                            variant="outlined"
                            data-test-id="nameCardtestID"
                            error={!!this.state.errors.nameCard}
                            value={this.state.nameCard}
                            onChange={this.setError("nameCard")}
                            InputProps={{
                                style: {
                                    color: "#757676",
                                    backgroundColor: !!this.state.errors.nameCard ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                    borderRadius: "6px",
                                    width: "100%",
                                    height: "55px",
                                },
                                startAdornment: <></>,
                            }}
                            helperText={this.state.errors.nameCard}
                        />
                        <TextField
                            id="standard-basic"
                            label={this.state.numbCard ? "" : configJSON.cardNumber}
                            className="userProfileinput"
                            fullWidth
                            variant="outlined"
                            data-test-id="numbCardID"
                            error={!!this.state.errors.numbCard}
                            value={this.state.numbCard}
                            onChange={this.setError("numbCard")}
                            InputProps={{
                                style: {
                                    color: "#757676",
                                    backgroundColor: !!this.state.errors.numbCard ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                    borderRadius: "6px",
                                    width: "100%",
                                    height: "55px",
                                },
                                endAdornment: <img src={visa} />,
                                startAdornment: <></>,
                            }}
                            helperText={this.state.errors.numbCard}
                        />
                        <Box className="expireMonthYearContainer">
                            {this.renderFormMenu()}
                            <FormControl variant="outlined" fullWidth>
                                <BioBox>
                                    <InputLabel style={{ color: !!this.state.errors.yearExp ? "#f96f62" : "grey" }}>
                                        Exp. Year
                                    </InputLabel>
                                    <Select
                                        variant="outlined"
                                        fullWidth
                                        label="Exp. Year"
                                        data-test-id="yearselecttestID"
                                        value={this.state.yearExp ? this.state.yearExp : " "}
                                        IconComponent={ExpandMoreIcon}
                                        onChange={this.handleYear}
                                        error={!!this.state.errors.yearExp}
                                        MenuProps={{ style: { marginTop: "50px" } }}
                                        style={{
                                            borderRadius: "8px",
                                            width: '100%',
                                            backgroundColor: !!this.state.errors.yearExp ? "rgba(229, 57, 53, 0.15)" : "#fff"
                                        }}
                                    >
                                        {years.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {this.state.errors.yearExp && <Typography style={{ color: 'red', fontSize: '10px' }}>{this.state.errors.yearExp}</Typography>}
                                </BioBox>
                            </FormControl>
                        </Box>
                        <TextField
                            data-test-id="selectcvvnumbertestId"
                            id="standard-basic"
                            label={this.state.numbCvv ? "" : configJSON.cvvNumber}
                            className="userProfileinput"
                            fullWidth
                            variant="outlined"
                            error={!!this.state.errors.numbCvv}
                            value={this.state.numbCvv}
                            onChange={this.setError("numbCvv")}
                            InputProps={{
                                style: {
                                    color: "#757676",
                                    backgroundColor: !!this.state.errors.numbCvv ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                    borderRadius: "6px",
                                    width: "100%",
                                    height: "55px",
                                },
                                startAdornment: <></>,
                            }}
                            helperText={this.state.errors.numbCvv}
                        />
                        <Typography className="userProfileheading">
                            {configJSON.billing}
                        </Typography>
                        <Box className="billingCvvCheckBoxContainer">
                            <FormControlLabel
                                control={<Checkbox />}
                                label={configJSON.billingAccount}
                            />
                        </Box>
                        <TextField
                            label={this.state.addressName ? "" : configJSON.cvvNumber}
                            className="userProfileinput"
                            fullWidth
                            variant="outlined"
                            data-test-id="cvvaddresstesteID"
                            error={!!this.state.errors.addressName}
                            value={this.state.addressName}
                            onChange={this.setError("addressName")}
                            InputProps={{
                                style: {
                                    color: "#757676",
                                    backgroundColor: !!this.state.errors.addressName ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                    borderRadius: "6px",
                                    width: "100%",
                                    height: "55px",
                                },
                                startAdornment: <></>,
                            }}
                            helperText={this.state.errors.addressName}
                        />
                        <TextField
                            id="standard-basic"
                            label={this.state.cityName ? "" : configJSON.city}
                            className="userProfileinput"
                            fullWidth
                            variant="outlined"
                            data-test-id="addresstesteID"
                            error={!!this.state.errors.cityName}
                            value={this.state.cityName}
                            onChange={this.setError("cityName")}
                            InputProps={{
                                style: {
                                    color: "#757676",
                                    backgroundColor: !!this.state.errors.cityName ? "rgba(229, 57, 53, 0.15)" : "#fff",
                                    borderRadius: "6px",
                                    width: "100%",
                                    height: "55px",
                                },
                                startAdornment: <></>,
                            }}
                            helperText={this.state.errors.cityName}

                        />
                        <Box className="expireMonthYearContainer">
                            {this.renderMenuList()}
                            {this.renderFormMenuItem()}
                        </Box>
                    </form>
                </Box>
                <Box className="userInfoItem2 talentAccountContainer">
                    <Typography className="talentAccountHeading">
                        {configJSON.tanentAccount}
                    </Typography>
                    <Box className="talentAccountBtnContainer">
                        <Button className="talentAccountBtn">{configJSON.no}</Button>
                        <Button className="talentAccountBtn talentAccountBtnActive">
                            {configJSON.yes}
                        </Button>
                    </Box>
                </Box>
                <Button type="submit" className="saveChangesBtn" onClick={(event) => this.handleButtonValidation(event)}>{configJSON.btnTextSaveChanges}</Button>
            </Box>
        );
    };

    renderFormMenuItem = () => {
        return (
            <FormControl variant="outlined" fullWidth>
                <TextField
                    id="standard-basic"
                    label={this.state.zipNumber ? "" : "Zipcode"}
                    className="userProfileinput"
                    fullWidth
                    variant="outlined"
                    data-test-id="zipselecttesttID"
                    error={!!this.state.errors.zipNumber}
                    value={this.state.zipNumber}
                    onChange={this.setError("zipNumber")}
                    InputProps={{
                        style: {
                            color: "#757676",
                            backgroundColor: !!this.state.errors.zipNumber ? "rgba(229, 57, 53, 0.15)" : "#fff",
                            borderRadius: "6px",
                            width: "100%",
                            height: "55px",
                        },
                        startAdornment: <></>,
                    }}
                    helperText={this.state.errors.zipNumber}
                    FormHelperTextProps={{
                        style:{margin:"0px"}
                    }}
                />
            </FormControl>
        )
    }


    renderFormMenu = () => {
        return (
            <FormControl variant="outlined" fullWidth>
                <BioBox>
                    <InputLabel style={{ color: !!this.state.errors.monthExp ? "#f96f62" : "grey" }}>
                        Exp. Month
                    </InputLabel>
                    <Select
                        variant="outlined"
                        fullWidth
                        label="Exp month"
                        data-test-id="monthselecttestId"
                        value={this.state.monthExp ? this.state.monthExp : " "}
                        IconComponent={ExpandMoreIcon}
                        onChange={this.handleMonth}
                        error={!!this.state.errors.monthExp}
                        MenuProps={{ style: { marginTop: "50px" } }}
                        style={{
                            borderRadius: "8px",
                            width: '100%',
                            backgroundColor: !!this.state.errors.monthExp ? "rgba(229, 57, 53, 0.15)" : "#fff",
                        }}
                    >
                        {this.state.months.map((month, index) => (
                            <MenuItem key={index} value={month}>
                                {month}
                            </MenuItem>
                        ))}
                    </Select>
                    {this.state.errors.monthExp && <Typography style={{ color: 'red', fontSize: '10px' }}>{this.state.errors.expMonth}</Typography>}
                </BioBox>
            </FormControl>
        )
    }



    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {!this.state.isModalOpen ? <>
                    {this.renderHeader()}
                    <Wrapper>
                        {this.renderUserInfo()}
                        {this.renderForm()}
                    </Wrapper>
                </> :
                <UserProfileContainer>
                    {this.renderUserProfile()}
                    {this.renderUserProfileResponsive()}
                </UserProfileContainer>}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const FormContainer = styled(Box)({
    marginTop: '40px',
    "@media (max-width: 599px)": {
        margin: '0px',

    },
})


const UserTypography = styled(Typography)({
    textTransform: 'none',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: "#101828",
    fontSize: "18px",
    fontWeight: 600,
    display: 'block',
    "@media (max-width: 384px)": {
        display: "none"
    }
})

const Personalypography = styled(Typography)({
    display: 'none',
    "@media (max-width: 384px)": {
        color: "#101828",
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
        justifyContent: 'flex-start',
        display: 'block',
    },
})


const Header = styled(Grid)({
    margin: '20px',
    "@media (max-width: 599px)": {
        margin: '-20px'
    },
})

const InfoText = styled(Typography)({
    textTransform: 'none',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    color: "#475467",
    fontSize: '14px',

})

const SubmitContainer = styled(Box)({
    display: "flex",
    justifyContent: 'space-between',
    width: "100%",
    borderBottom: "2px solid #D9D9D9",
    paddingBottom: "20px",
    "@media (max-width: 768px)": {
        flexDirection: "column",
        gap: "20px"
    },
})

const CancelButton = styled(Button)({
    display: "flex",
    padding: "10px 14px",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFF",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    color: "#344054",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    border: "1px solid #D0D5DD",
    textTransform: 'none',
    marginRight: '10px',
    borderRadius: '8px'
})

const LineGrid = styled(Grid)({
    backgroundColor: '#D9D9D9',
    width: '100%',
    marginTop: '20px',
    height: '2px',
    marginLeft: '9px',
    marginBottom: '20px',
    display: 'flex'

})

const BioBox = styled(Box)({
    width: '100%'
})

const FontImg = styled("img")({
    marginLeft: '10px'
})

const FontContainer = styled(Grid)({
    alignItems: 'center',
    display: 'flex',
    marginLeft: '10px',
    "@media(max-width:1634px)": {
        justifyContent: 'flex-start',
    },
    "& .MuiButton-root": {
        minWidth: "40px"
    }

})

const EmalTextField = styled(TextField)({
    "& .MuiOutlinedInput-inputAdornedStart": {
        paddingLeft: "5px"
    }
})

const HelpCircle = styled("img")({
    marginLeft: '10px',
    marginTop: '13px'
})
const QuesrionCircle = styled("img")({
    marginLeft: '3px',
    marginBottom: '5px',
    marginTop: '2px'
})

const BioContainer = styled(Box)({
    width: '100%',
    borderRadius: '12px',
    display: 'flex',
    border: '1px solid #EAECF0',
    marginTop: '20px',
    alignItems: "flex-start",
    gap: "20px",
    padding: "20px",
    boxSizing: "border-box",
    "& .fileBox": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    },
    "& .fileProgress": {
        display: "flex",
        gap: "20px",
        justifyContent: "space-between",
        alignItems: "center",
    }
})

const DocUploadText = styled(Typography)({
    color: "#344054",
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
});

const PercentText = styled(Typography)({
    color: '#475467',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
})

const SizeText = styled(Typography)({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: "#475467"
})

const BackIcon = styled("img")({
    display: "none",
    "@media (max-width: 403px)": {
        padding: "10px 5% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        marginTop: "20px",
        marginBottom: '20px',
    }
})

const CurserTypography = styled(Typography)({
    cursor: "pointer"
})

const UserGrid = styled(Grid)({
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
})

const QualImage = styled("img")({
    objectFit: 'cover',
    display: 'flex',

    "@media (max-width: 594px)": {
        margin: "33px",
    },
    "@media (max-width: 387px)": {
        display: 'none',
        margin: '70px',
    },
})

const FeatureImg = styled("img")({
    alignItems: 'center',
    display: 'flex',
    width: '100%',
})

const ErrorText = styled(Typography)({
    color: "red",
    fontSize: "12px",
    marginLeft: "16px"
})

const AvatarImg = styled("img")({
    width: "80px",
    height: "80px",
    objectFit: "contain",
    borderRadius: '44px'
})

const NameText = styled(Typography)({
    color: '#344054',
    fontWeight: 600,
    fontSize: '14px',
})

const TextName = styled(Typography)({
    color: '#344054',
    fontWeight: 600,
    fontSize: '14px',
    "@media (max-width: 959px)": {
        display: 'none'
    }
})

const FirstName = styled(Typography)({
    "@media (max-width: 959px)": {
        color: '#344054',
        fontWeight: 600,
        fontSize: '14px',
        marginTop: '13%',
        display: 'block',
    },
    display: 'none',
})

const LastName = styled(Typography)({
    display: 'none',
    "@media (max-width: 959px)": {
        color: '#344054',
        fontWeight: 600,
        fontSize: '14px',
        marginTop: '8%',
        marginBottom: '5%',
        display: 'block',

    },
})

const ButtonContainer = styled(Box)({
    display: 'flex',
    width: '100%'
});

const FormButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: "flex-end",
    width: '100%'
})

const ImgContainer = styled(Grid)({
    width: '100%',
    height: '180px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
})


const DocContainer = styled("img")({
    maxWidth: '100%', height: 'auto'
})

const SaveButton = styled(Button)({
    display: "flex",
    padding: "10px 14px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    border: "1px solid #7F56D9",
    background: "#7F56D9",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    textTransform: 'none',
    borderRadius: '8px'
})

const Progressline = styled(LinearProgress)({
    width: "150%",
    height: "10px",
    borderRadius: "20px",
})
const UserProfileContainer = styled(Box)({
    backgroundColor: "#fff",
    padding: "20px 8% 20px 20px",
    "@media(max-width:768px)": {
        padding: "20px ",
    },
    "& .iconSection": {
        padding: " 0px",
    },
    "& .topIcon": {
        width: "140px",
        height: "49px",
        marginLeft: "-8px",
    },
    "& .userInfoConfirmationContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 0px",
    },
    "& .userInfoConfirmationLeft": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
    },
    "& .userInfoConfirmationLeftHeading": {
        color: "#101828",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "28px",
    },
    "& .expMonthYearContainer": {
        display: "none",
        "@media(max-width:768px)": {
            display: "block",
        },
    },
    "& .userInfoConfirmationLeftSubHeading": {
        color: "#475467",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    "& .userInfoConfirmationRight": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        gap: "12px",
    },
    "& .userInfoConfirmationCancelBtn": {
        borderRadius: "8px",
        padding: "10px 14px",
        border: "1px solid #D0D5DD",
        background: "#FFF",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        color: "#344054",

        fontSize: "14px",
        fontWeight: "600",
        textTransform: "capitalize",
    },
    "& .userInfoConfirmationSaveBtn": {
        display: "flex",
        padding: "10px 14px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        borderFadius: "8px",
        border: "1px solid #7F56D9",
        background: "#7F56D9",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        color: "#FFF",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
    },
    "& .userInfoItem": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "30px 0px",
        "& > :first-child": {
            width: "20%",
        },
    },
    "& .userInfoLabel": {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: '140%',
        letterSpacing: "-0.28px",
        color: "#757676"
    },
    "& .userProfileSubLabel": {
        color: "#475467",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    "& .userInfoInput": {
        width: "512px",
        height: "44px",
        borderRadius: "6px",
        background: "#FFF",
    },
    "& .avatarSelectioninput": {
        display: "none",
    },
    "& .avatarImgContainer": {
        width: "64px",
        height: "64px",
        borderRadius: "200px",
    },
    "& .avatarImg": {
        width: "100%",
        height: "100%",
        borderRadius: "200px",
    },

    "& .userProfilePicker": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "20px",
        alignItems: "flex-start",
        marginRight: '20px'
    },
    "& .userInfoItem1": {
        borderTop: "1px solid #D9D9D9",
    },
    "& .userInfoItem2": {
        borderTop: "1px solid #D9D9D9",
        marginTop: "15px",
    },
    "& .userProfilePickerLabelContainer": {
        display: "flex",
        flexDirection: "column",
    },
    "& .userInfoItem3": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "20px",
        alignItems: "flex-start",
    },
    "& .billingCvvSection": {
        borderTop: "1px solid #D9D9D9",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "30px",
    },
    "& .userInfoItemLabelContainer": {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
    },

    "& .userInfoLabelProfile": {
        color: "#475467",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    "& .countryCode": {
        width: "83px",
    },
    "& .phoneNumberContainer": {
        display: "flex",
        gap: "20px",
    },
    "& .phoneNumberInput": {
        width: "431px",
    },
    "& .creditCardInfoText": {
        color: "#252525",
        fontSize: "18px !important",
        fontStyle: "normal",
        fontWeight: 600,
        padding: "20px 0px",
        marginTop: "50px",
    },
    "& .expireDateInputContainer": {
        display: "flex",
        gap: "10%",
        "@media(max-width:768px)": {
            gap: "10px",
        },
    },
    "& .expireDateSelectContainer": {
        width: "70%",
        display: "flex",
        alignItems: "center",
        gap: "20px",
    },
    "& .expireDateSelectLabel": {
        color: "#757676",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        width: "200px",
    },

    "& .expireDateContainer": {
        "@media(max-width:768px)": {
            display: "none",
        },
    },
    "& .userInfoexpireDateInput": {
        width: "250px",
    },
    "& .userInfoLabelExpireDate": {
        width: "30%",
    },
    "& .stateZipContainer": {
        "@media(max-width:768px)": {
            display: "none",
        },
    },
    "& .expireYearInputContainer": {
        width: "50%",
        display: "flex",
        alignItems: "center",
        gap: "20px",
        "@media(max-width:768px)": {
            gap: "0px",
        },
    },
    "& .billingCvvCheckBoxContainer": {
        width: "100%",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#2DC9A1 !important",
    },
    "& .talentAccountHeading": {
        color: "#252525",
        fontSize: "18px",
        fontWeight: 600,
    },
    "& .talentAccountContainer": {
        paddingTop: "60px",
        paddingBottom: "20px",
        display: "flex",
        gap: "40px",
        "@media(max-width:768px)": {
            gap: "0px",
            justifyContent: "space-between",
        },
        "@media(max-width:476px)": {
            flexDirection: "column",
            gap: "20px",
        },
    },
    "& .talentAccountBtnContainer": {
        display: "flex",
        gap: "10px",
    },
    "& .talentAccountBtn": {
        width: "49px",
        height: "26px",
        borderRadius: "13px",
        background: "#fff",
        textTransform: "capitalize",
        color: "#252525",
        fontSize: "14px",
        fontWeight: 600,
    },
    "& .talentAccountBtnActive": {
        color: "#fff",
        background: "#6551EA",
    },
    "& .userInfoSaveContainer": {
        display: "flex",
        justifyContent: "flex-end",
        padding: "20px 0px",
    },
    "& .userInfoSaveBtnContainer": {
        paddingTop: "60px",
        display: "flex",
        gap: "20px",
    },
    "& .userProfile": {
        display: "block",
        "@media(max-width:768px)": {
            display: "none",
        },
    },

    "& .userInfoCancelBtn": {
        borderRadius: "8px",
        border: "1px solid #D0D5DD",
        background: "#FFF",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        textTransform: "capitalize",
    },
    "& .userInfoSaveBtn": {
        borderRadius: "8px",
        border: "1px solid #7F56D9",
        background: "#252525",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        textTransform: "capitalize",
        color: "#fff",
    },
    "& .userProfileResponsive": {
        display: "none",
        "@media(max-width:768px)": {
            display: "block",
        },
    },
    "& .profilePhotoContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
    },
    "& .userProfileheading": {
        color: "#252525",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "30px",
        borderBottom: "1px solid #D9D9D9",
        paddingBottom: "15px",
        textAlign: "left",
        width: "100%",
    },
    "& .userProfileFormContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px 0px",
    },
    "& .userProfileinput": {
        borderRadius: "6px",
    },
    "& .expireMonthYearContainer": {
        display: "flex",
        gap: "16px",
        width: "100%",
    },
    "& .saveChangesBtn": {
        borderRadius: "6px",
        background: "#252525",
        width: "100%",
        color: "#fff",
        height: "44px",
        marginTop: "70px",
    },
});

const Wrapper = styled(Box)({
    width: "72%",
    marginLeft: "35px",
    "@media(max-width:768px)": {
        width: "100%",
        marginLeft: "unset",
        padding: "0 25px 0 25px",
        boxSizing: "border-box"
    }
});

const BioGrid = styled(Grid)({
    display: 'flex',
    "@media(max-width: 560px)": {
        flexDirection: "column",
        gap: "20px"
    }
});

const SnippetText = styled(Typography)({
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#475467"
})

const BioBoxSelect = styled(Grid)({});

// Customizable Area End