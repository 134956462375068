//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area Start
import {
  Container,
  Input,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box, Typography,TextField, styled, Drawer,List,ListItem,ListItemIcon,ListItemText
} from "@material-ui/core";
import { heart ,time,location,date,search,quilaImage,menuOption,pic,button,support,settings} from "./assets";

// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start
interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#376fd0",
      contrastText: "#fff",
    },
    error: {
      main: "#f44336",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderStartingPageNavigation = () => {
    return (
      <StartingPageNav>
        <img src={quilaImage} className="navLogo" />
        <Box className="navProfileIcon" data-test-id="drawerTestId" onClick={this.handlleDrawer} >
          <img src={menuOption} alt="" />
        </Box>
      </StartingPageNav>
    );
  };

  renderDrawer = () => {
    return (
      <MenuDrawer
        anchor={"left"}
        open={this.state.openDrawer}
        onClose={this.handlleDrawer}
        className="menuDrawer"
      >
        <img src={quilaImage} alt="" className="drawerTopIcon" />
        <Divider />
        <Box className="drawerMenuContainer">
          <List>
            {this.state.drawerMenu.map((item, index) => {
              return (
                <ListItem button key={index}>
                  <ListItemIcon>
                    <img src={item.icon} alt="" />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              );
            })}
          </List>
          <Box>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <img src={support} alt="" />
                </ListItemIcon>
                <ListItemText primary={"Support"} />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <img src={settings} alt="" />
                </ListItemIcon>
                <ListItemText primary={"Settings"} />
              </ListItem>
            </List>
            <Divider className="divider" />
            <Box className="drawerBottomProfileSection">
              <img src={pic} alt="" className="drawerBottomAvatar" />
              <Box>
                <Typography className="drawerBottomName">
                  Camila Y. Cuesta
                </Typography>
                <Typography className="drawerBottomEmail">
                  camila@quilabeauty.com
                </Typography>
              </Box>
              <img src={button} alt="" className="logoutBtn" />
            </Box>
          </Box>
        </Box>
      </MenuDrawer>
    );
  };

  renderImages=()=>{
      return(
        <>
        <Box className="searchSection">
        <Box className="searchContainer">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={4}>
              <TextField
                type="text"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <img src={search} className="searchIcon" />
                  ),
                }}
                className="searchBar"
                placeholder="Search for service or business name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={4}>
              <TextField
                type="text"
                variant="outlined"
                InputProps={{
                  startAdornment: <img src={location} className="searchIcon" />,
                }}
                className="searchBar"
                placeholder="Location"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <TextField
                type="text"
                variant="outlined"
                InputProps={{
                  startAdornment: <img src={date} className="searchIcon" />,
                }}
                className="searchBar"
                placeholder="Date"
              />
             
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <TextField
                type="text"
                variant="outlined"
                InputProps={{
                  startAdornment: <img src={time} className="searchIcon" />,
                }}
                className="searchBar"
                placeholder="Time"
              />
             
            </Grid>
          </Grid>

          <Box className="dateTimePickerContainer"></Box>
        </Box>
        <Box className="menuItemContainer">
          {this.state.imagesWithText.map((item,index) => {
            return (
              <Box className="menuItem" key={index}>
                <img src={item.img} className="menuItemImg" />
                <Typography className="menuItemText">{item.info}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
   </>
      )
    }


    rendersecondImages=()=>{
      return(
      <>
      <Typography className="carouselHeading">Special Offers</Typography>
      <Box className="SpecialOffers" >
        {this.state.imagesText.map((item) => {
          return this.renderSpecialOffersCard(item)
        })}        
    </Box>
    </>

      )
    }

    renderSpecialOffersCard = (item) => {
      return (
        <Box className="SpecialOffersCard">
          <Box className="specialOfferCardImageContainer">
            <img src={item.src} alt="" className="offerCardImg" />
            <img src={heart} alt="" className="offerCardHeart" />
          </Box>
          <Box className="offerContentContainer">
            <Typography className="offerCardTitle">{item.text}</Typography>
            <Typography className="offerCardSubTitle">{item.textSecond}</Typography>
            <Box>
              {Array.from({ length: Math.floor(item.starRating) }, (_, index) => (
                <span key={index}>&#9733;</span>
              ))}
              {item.starRating % 1 !== 0 && <span>&#9734;</span>}
              <span className="offerRating">{item.rating}</span>
            </Box>
            <Typography className="offerCardTag">{item.type}</Typography>
          </Box>
        </Box>
      );
    };

    renderThirdImages=()=>{
      return(
        <Box style={{ overflowX: 'auto' ,marginTop:"5%"}}>
        <Typography className="carouselHeading">Near You</Typography>
        <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {this.state.imagesRoom.map((item, index) => (
            <Grid item key={index} className="SpecialOffersCard">
              <img src={item}  className="offerCardImg"/>
            </Grid>
          ))}
        </Grid>
      </Box>
      )
    }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
        <Box style={{}}>
        {this.renderStartingPageNavigation()}
        <Divider />
        <StartingPageContainer>
        {this.renderImages()}
        {this.rendersecondImages()}
        {this.renderThirdImages()}
        {this.renderDrawer()}
        </StartingPageContainer>
        </Box>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const StartingPageNav = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  padding: "20px 120px",
  "@media(max-width:991px)": {
    padding: "20px 100px",
  },
  "@media(max-width:768px)": {
    padding: "20px 80px",
  },
  "@media(max-width:576px)": {
    padding: "20px 30px",
  },
  "@media(max-width:476px)": {
    padding: "20px 20px",
  },
});
const MenuDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    width: "300px",
    "@media(max-width:991px)": {
      width: "280px",
    },
    "@media(max-width:768px)": {
      width: "260px",
    },
    "@media(max-width:576px)": {
      width: "240px",
    },
    "@media(max-width:476px)": {
      width: "220px",
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: "28px",
  },
  "& .drawerMenuContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
    height: "100%",
    gap: "100px",
  },
  "& .drawerTopIcon": {
    width: "160px",
    height: "60px",
    margin: "20px 0px",
  },
  "& .drawerBottomProfileSection": {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",

    gap: "10px",
  },
  "& .drawerBottomName": {
    fontSize: "16px",
    fontWeight: "800",
  },
  "& .drawerBottomEmail": {
    fontSize: "14px",
    fontWeight: "400",
  },
  "& .divider": {
    margin: "25px 0px",
  },
  "& .drawerBottomAvatar": {
    widht: "70px",
    height: "70px",
    borderRadius: "50%",
  },
  "& .dateTimePickerContainer": {
    width: "100%",
  },
});
const StartingPageContainer = styled(Box)({
  padding: "20px 120px",

  "@media(max-width:991px)": {
    padding: "20px 100px",
  },
  "@media(max-width:768px)": {
    padding: "20px 80px",
  },
  "@media(max-width:576px)": {
    padding: "20px 30px",
  },
  "@media(max-width:476px)": {
    padding: "20px 20px",
  },
  "& .navProfileIcon": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  "& .searchSection": {
    display: "flex",
    flexDirection: "column",

    gap: "20px",
  },
  "& .searchContainer": {
    display: "flex",
    gap: "20px",
  },
  "& .searchBar": {
    width: "100%",
  },
  "& .searchIcon": {
    marginRight: "10px",
  },
  "& .DateBar": {
    width: "25%",
  },
  "& .menuItemContainer": {
    display: "flex",
    gap: "20px",
    overflow: "scroll",
    scrollbarWidth: "none",
    marginBottom: "70px",
  },
  "&n .menuItem": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
  },
  "& .menuItemImg": {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  },
  "& .menuItemText": {
    fontSize:"10px",
    fontWeight:500,
    color:"#2E2E2E",
    marginTop:"10px",
    marginLeft:"10%",
  },
  "& .carouselHeading": {
    textAlign: "left",
    fontSize: "22px",
    fontWeight: "800",
    padding: "20px 0px",
  },
  "& .SpecialOffers": {
    display: "flex",
    gap: "20px",
    overflow: "scroll"
  },
  
  "& .SpecialOffersCard": {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "336px",
    height: "400px",
    flexShrink: "0"
  },
  "& .specialOfferCardImageContainer": {
    position: "relative",
  },
  "& .offerCardImg": {
    width: "100%",
    height: "200px",
    borderRadius: "8px",
  },
  "& .offerCardHeart": {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "30px",
    height: "30px",
  },
  "& .offerContentContainer": {
    gap: "5px",
    display: "flex",
    flexDirection: "column",
  },
  "& .offerRating": {
    marginLeft: "10px",
  },
  "& .offerCardTitle": {
    color: "#2E2E2E", 
    fontWeight: 500, 
    fontSize: "16px",
    lineHeight: "22.4px",
     marginTop: "2%"
  },
  "& .offerCardSubTitle":{
    color: "#6E6E6E", 
    fontWeight: 500, 
    fontSize: "10px", 
    width: "240px"
  },
  "& .offerCardTag": {
    fontSize: "10px",
    padding: "0px 5px",
    marginTop: "10px",
    border: "1px solid #D9D9D9",
    borderRadius: "3px",
    width: "max-content",
  },
  "& .datePickerContainer": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 15px",
    height: "55px",
    border: "1px solid #c4c4c4",
    borderRadius: "5px",
  },
});
// Customizable Area End
