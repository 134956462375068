Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.backDashboardText = "Back to dashboard";
exports.settingText = "Settings";
exports.notificationText = "Notification settings";
exports.notificationSecondText ="We may still send you important notifications about your account outside of your notification settings.";
exports.commentText = "Comments";
exports.commentTextOne = "These are notifications for comments";
exports.commentTextSecond = "on your posts and replies to your comments.";
exports.emailText = "Email";
exports.smsText = "SMS";
exports.tagsText = "Tags"; 
exports.tagsTextOne ="These are notifications for when someone";
exports.tagsTextTwo = "tags you in a comment, post or story.";
exports.pushText = "Push";
exports.remindertext = "Reminders";
exports.reminderTextOne = "These are notifications to remind you of";
exports.reminderTextTwo = "updates you might have missed.";
exports.activityText = "More activity about you";
exports.activityTextOne = "These are notifications for posts on your";
exports.activityTextTwo = "profile, likes and other reactions to your";
exports.activityTextThree = "posts, and more.";
exports.number = "4";
exports.teamText = "Team";
exports.emailIdtext = "Olivia Rhye";
exports.emailIdtextSecond = "olivia@untitledui.com";
exports.suggestionText = "Suggestions / Feedback";
exports.blockuserText = "Blocked Users";
exports.showText = "Show address only after approval";
exports.hideText = "Hide Only Talent Accounts";
exports.hideAllText = "Hide All Account(s)";
exports.privacyText = "Privacy";
exports.alertText = "Alert comments/reviews";
exports.pauseText = "Pause likes";
exports.pauseBookingText = "Pause Bookings";
exports.pushNotificationText = "Push Notifications";
exports.notificationText = "Notification settings";
exports.helpText = "Help";
exports.savebtnText = "Save";
exports.settingApiMethod  = "PATCH";
exports.settingApiEndPoint = "account_block/accounts/modify_account_settings";
exports.settingGetApiEndPoint = "account_block/accounts/get_account_settings";
exports.notificationEndPoint = "account_block/accounts/modify_notification_settings";
exports.notificationGetEndPoint = "account_block/accounts/get_notification_settings";
exports.notificationMethod = "PUT";
// Customizable Area End