export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const leftArrow = require("../assets/leftArrow.png");
export const keyImage = require("../assets/keyImage.png");
export const quilaImage = require("../assets/quilaImage.png");
export const blackArrow = require("../assets/blackArrow.png");
export const email = require("../assets/email.png");
export const checkIcon  = require("../assets/checkIcon.png");
export const lock  = require("../assets/lock.png");
export const checkCircle = require("../assets/check-circle.png");
export const redCheck = require("../assets/redIcon.png");








