import React from "react";
// Customizable Area Start
import { Box, Button, Typography, styled} from "@material-ui/core";
import { QuilaLogo, EmailIcon, BackIcon, CheckImg } from "./assets";
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
export const configJSON = require("./config");
import OtpInput from "react-otp-input";
// Customizable Area End

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderVerifyEmail = () => {
    return (
        <>
            {<VerifyEmailMainContainer>
                <Box className="subContainer">
                    <Box className="emailIconContainer">
                        <img className="emailIcon"
                            src={CheckImg} />
                    </Box>

                    <Box className="verifyEmailTextBox">
                        <Typography className="verifyEmailTextStyle">{configJSON.emailVerifiedText}</Typography>
                        <Typography className="checkVerifyEmailText">{configJSON.resetPassText}<br></br> {configJSON.loginText}</Typography>
                    </Box>

                    <Button className="btnBox" data-test-id="continueBtnTestID" onClick={this.navigateToLoginPage} >
                        <Typography className="btnText">{configJSON.continueBtnText}</Typography>
                    </Button>

                    <Box className="resentTextContainer">
                        <Typography className="receiveEmailText">{configJSON.receiveEmailtext}</Typography>
                        <Typography className="resendBtn">{configJSON.resendText}</Typography>
                    </Box>
                </Box>
            </VerifyEmailMainContainer>}
        </>
    )
}

renderOtpContainer = () => {
    return (
        <OtpMainContainer>
            { this.state.validatetOtp ? <OtpInput
                data-test-id="otpInputID"
                onChange={this.handleOTPChange}
                value={this.state.otpInput}
                numInputs={4}
                containerStyle={"otp-style"}
                inputStyle={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "8px",
                    border: "2px solid red",
                    background: "rgba(229, 57, 53, 0.15)",
                    margin: "0px 3px",
                    color: "#6551EA",
                    fontSize: "25px",
                    textAlign: "center"
                }}
                renderInput={(props) => <input {...props} />}
            /> : <OtpInput
                data-test-id="otpInputID"
                onChange={this.handleOTPChange}
                value={this.state.otpInput}
                numInputs={4}
                containerStyle={"otp-style"}
                inputStyle={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "8px",
                    border: "2px solid #6551EA",
                    background: "#FFF",
                    margin: "0px 3px",
                    color: "#6551EA",
                    fontSize: "25px",
                    textAlign: "center"
                }}
                renderInput={(props) => <input {...props} />}
            />}
        </OtpMainContainer>
    )
}

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainContainer>
      <img className="logoStyle" src={QuilaLogo} />
      <img className="backIcon" src={BackIcon} />
      <VerifyEmailMainContainer>
          {!this.state.verifyEmail ?
              <Box className="subContainer">
                  <Box className="emailIconContainer">
                      <img className="emailIcon"
                          src={EmailIcon} />
                  </Box>
                  <Box className="textContainer">
                      <Typography className="checkEmailText">{configJSON.checkEmailText}</Typography>
                      <Typography className="verificationText">{configJSON.verificationText}</Typography>
                      <Typography className="cmailyText">{this.state.emailCheck}</Typography>
                  </Box>
                  {this.state.otpView ? this.renderOtpContainer() : null}
                  {this.state.otpView ?
                      <Button className="btnBox" data-test-id="verifytestid" onClick={this.handleNavigateVerifyEmail}>
                          <Typography className="btnText" >{configJSON.verifyText}</Typography>
                      </Button>
                      : <Button className="btnBox"
                          data-test-id="otpViewTestId"
                          onClick={this.handleNavigate} >
                          <Typography className="btnText">{configJSON.enterCodeBtnText}</Typography>
                      </Button>}
                  {this.state.otpView ? <Box className="resentTextContainer">
                      <Typography className="receiveEmailText">{configJSON.receiveEmailtext}</Typography>
                      <Typography className="resendBtn" onClick={this.postResendOtpFunction} data-test-id="resendBtnId">{configJSON.resendText}</Typography>
                  </Box> : null}
              </Box>
              : this.renderVerifyEmail()}
      </VerifyEmailMainContainer>
  </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .logoStyle": {
    padding: "15px 20px",
    width: "11%",
    display: "block",
    "@media (max-width:598px)": {
      display: "none"
    }
  },
  "& .backIcon": {
    display: "none",
    "@media (max-width:598px)": {
      height: "25px",
      width: "25px",
      padding: "20px",
      display: "block"
    }
  }
});

const VerifyEmailMainContainer = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "25px 0px",
  "& .subContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    padding: "4%"
  },
  "& .emailIconContainer": {
    height: "56px",
    width: "56px",
    border: "1px solid #EAECF0",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .emailIcon": {
    width: "28px",
    height: "28px",
    flexShrink: 0
  },
  "& .checkEmailText": {
    color: "#101828",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.48px"
  },
  "& .verificationText": {
    color: "#757676",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px ",
    letterSpacing: "-0.14px",
    marginTop: "10px"
  },
  "& .cmailyText": {
    color: "#757676",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.14px",
    textAlign: "center"
  },
  "& .btnBox": {
    width: "358px",
    height: "44px",
    flexShrink: 0,
    borderRadius: "6px",
    background: "#0E1518",
    color: "white",
    textTransform: "initial",
    "& .MuiButton-root": {
      textDecoration: "none",
      backgroundColor: "black",
      textTransform: "initial"
    },
    "& .hover": {
      hover: "none",
      background: "#0E1518"
    },
    "& .MuiButton-root:hover": {
      textDecoration: "none",
      backgroundColor: "black"
    }
  },
  "& .btnText": {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    textTransform: "initial",
    "& .MuiButton-root:hover": {
      textDecoration: "none",
      backgroundColor: "black"
    }
  },
  "& .resentTextContainer": {
    display: "flex",
    alignItems: "center",
    marginTop: "10px"
  },
  "& .resendBtn": {
    color: "#6551EA",
    fontStyle: "normal",
    marginLeft: "5px"
  },
  "& .receiveEmailText": {
    color: "#475467",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWWeight: 400,
    lineHeight: "20px"
  },
  "& .checkVerifyEmailText": {
    color: " #757676",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.14px"
  },
  "& .verifyEmailTextBox": {
    top: "10px"
  },
  "& .verifyEmailTextStyle": {
    color: "#101828",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.48px",
    marginBottom: "10px"
  }
});

const OtpMainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 5px 10px"
});

// Customizable Area End
