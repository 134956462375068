import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  TextField,
  styled,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import {
  logoImg,
  sideImage,
  googleLogoImg,
  backIcon
} from "./assets"
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
export const configJSON = require("./config");
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDate = () => {
    const customIcon = <ExpandMoreIcon style={{ position: "absolute", top: "50%", right: "12px", transform: "translateY(-50%)" }}
    />;
    return (
      <Box className="input_field_box" gridGap={10}>
        <FormControl className="date_select_field">
          <Select
            data-test-id="dobTestID"
            error={this.state.dobMsg}
            style={this.state.dobMsg ? webStyle.dateBox : {}}
            defaultValue={Date}
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Date"
            inputProps={{ "aria-label": "Without label" }}
            value={this.state.dobPlaceholder}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleDateChange({ target: { value: parseInt(event.target.value as string, 10) } })}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "20px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => customIcon}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.dobPlaceholder}>{this.state.dobPlaceholder}</MenuItem>
            {this.state.dayValue.map((item: number) => (
              <MenuItem key={item} value={item} style={webStyle.dateStyle}
                disabled={this.state.selectYear === new Date().getFullYear() && this.state.selectMonth - 1 === new Date().getMonth() && item > new Date().getDate() - 1}
              >
                {item}
              </MenuItem>
            ))}

          </Select>
        </FormControl>
        <FormControl className="date_select_field">
          <Select
            data-test-id="dobMonthTestID"
            error={this.state.monthMsg}
            style={this.state.monthMsg ? webStyle.dateBox : {}}
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Date"
            inputProps={{ "aria-label": "Without label" }}
            placeholder="date"
            value={this.state.monthPlaceholder}
            onChange={this.handleMonthChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "10px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => customIcon}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.monthPlaceholder}>{this.state.monthPlaceholder}</MenuItem>
            {this.state.monthValue.map((item: { id: number; monthTitle: string }) => (

              <MenuItem key={item.id} value={item.id} style={webStyle.dateStyle}
                disabled={this.state.selectYear === new Date().getFullYear() && item.id > new Date().getMonth() + 1}
              >
                {item.monthTitle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="date_select_field">
          <Select
            data-test-id="dobYearTestID"
            error={this.state.yearMsg}
            className="date_select_field"
            style={this.state.yearMsg ? webStyle.dateBox : {}}
            variant="outlined"
            displayEmpty
            labelId="MonthDate"
            inputProps={{ "aria-label": "Without label" }}
            onChange={this.handleYearChange}
            placeholder="Month"
            value={this.state.yearPlaceholder}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "10px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => customIcon}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.yearPlaceholder}>{this.state.yearPlaceholder}</MenuItem>
            {this.state.yearValue.map((item: number) => (
              <MenuItem key={item} value={item} style={webStyle.dateStyle}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  renderSignupBox = () => {
    const getBorderBoxStyle = () => {
      return this.state.isLogin ? "1px solid #6551ea" : "1px solid #757676";
    };

    const getBoxTextColor = () => {
      return this.state.isLogin ? "#6551ea" : "#757676";
    };
    return (
      <LoginSignupBox>
        <LoginText
         data-test-id="toggleID"
          style={{
            borderBottom: getBorderBoxStyle(),
            color: getBoxTextColor(),
          }}
          onClick={() => this.toggleForm(false)}>
          {configJSON.loginText}
        </LoginText>
        <LoginText style={{ borderBottom: this.state.isLogin ? "1px solid #757676" : "1px solid #6551ea", color: this.state.isLogin ? "#757676" : "#6551ea" }}
         >{configJSON.btnTextSignUp}</LoginText>
      </LoginSignupBox>
    )
  };

  renderFirstNameText =()=>{
    return(
      <TextField
      data-test-id="fisrtnameID"
      error={this.state.fnameError}
      required={this.state.fnameError ? true : false}
      label={this.state.fname ? "" : "First Name"}
      variant="outlined"
      value={this.state.fname}
      style={webStyle.textInput}
      onChange={(e) => this.setFname(e.target.value)}
      className="input_field_config"
      InputLabelProps={{
        disableAnimation: true,
        shrink: false,
        style: {
          color: this.state.fnameError ? "red" : "#757676",
          opacity: 1,
          backgroundColor: this.state.fnameError ? "rgba(229, 57, 53, 0.15)" : "#fff"
        }
      }}
      InputProps={{
        style: {
          color: this.state.fnameError ? "red" : "#757676",
          border: this.state.fnameError ? "1px solid red" : "",
          backgroundColor: this.state.fnameError ? "rgba(229, 57, 53, 0.15)" : "#fff"
        }
      }}
    />
    )
  }

  renderEmailText = () => {
    return (
      <TextField
        data-test-id="emailTestID"
        error={this.state.emailError}
        required={this.state.emailError ? true : false}
        label={this.state.emailID ? "" : "Email address"}
        variant="outlined"
        style={webStyle.inputEmailStyle}
        className="input_field_config"
        value={this.state.emailID}
        onChange={(e) => this.setEmail(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.emailError ? "red" : "#757676",
            opacity: 1,
            backgroundColor: this.state.emailError ? "rgba(229, 57, 53, 0.15)" : "#fff",
            borderRadius: "7px"
          }
        }}
        InputProps={{
          style: {
            color: this.state.emailError ? "red" : "#757676",
            border: this.state.emailError ? "1px solid red" : "",
            backgroundColor: this.state.emailError ? "rgba(229, 57, 53, 0.15)" : "#fff"
          }
        }}
      />
    )
  }

  renderPasswordText = () => {
    return (
      <TextField
        data-test-id="paswordTestID"
        error={this.state.createPassError}
        required={this.state.createPassError ? true : false}
        label={this.state.createPassword ? " " : "Create a password"}
        variant="outlined"
        style={webStyle.inputEmailStyle}
        className="input_field_config"
        value={this.state.createPassword}
        onChange={(e) => this.setPassword(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.createPassError ? "red" : "#757676",
            opacity: 1,
            backgroundColor: this.state.createPassError ? "rgba(229, 57, 53, 0.15)" : "#fff",
            borderRadius: "7px"
          }
        }}
        InputProps={{
          style: {
            color: this.state.createPassError ? "red" : "#757676",
            border: this.state.createPassError ? "1px solid red" : "",
            backgroundColor: this.state.createPassError ? "rgba(229, 57, 53, 0.15)" : "#fff",
          },
        }}
      />
    )
  }

  renderLastNameText = () => {
    return (
      <TextField
        data-test-id="lasttnameID"
        error={this.state.lnameError}
        required={this.state.lnameError ? true : false}
        label={this.state.lname ? "" : "Last Name"}
        variant="outlined"
        style={webStyle.textInput}
        className="input_field_config"
        value={this.state.lname}
        onChange={(e) => this.setLname(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.lnameError ? "red" : "#757676",
            opacity: 1,
            backgroundColor: this.state.lnameError ? "rgba(229, 57, 53, 0.15)" : "#fff"
          }
        }}
        InputProps={{
          style: {
            color: this.state.lnameError ? "red" : "#757676",
            border: this.state.lnameError ? "1px solid red" : "",
            backgroundColor: this.state.lnameError ? "rgba(229, 57, 53, 0.15)" : "#fff"
          }
        }}
      />
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks

      <Grid container>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <BackIcon src={backIcon} />
          <LogoIconContainer>
            <QualImage src={logoImg} alt="" />
            <MainTextinputContainer>
              <form onSubmit={this.handleCheck} data-test-id="signInBtn">
                <Box className="login_box">
                  <Box className="tab_box">
                  {this.renderSignupBox()}
                  </Box>

                  <Box className="input_field_box">
                   {this.renderFirstNameText()}
                    {this.renderLastNameText()}
                  </Box>
                  {this.renderEmailText()}
                  {this.renderPasswordText()}                                 
                {  this.state.createPassError ?
                <Typography style={webStyle.passwordTextError}>{configJSON.passMsg}</Typography> 
              
                : 
                <Typography style={webStyle.passwordText}>{configJSON.pCharacters}{configJSON.pCharactersTwo}</Typography>}
                  <Typography style={this.state.dobMsg ? webStyle.dobStyleError : webStyle.dobStyle}>{configJSON.dobText}</Typography>
                  {this.renderDate()}

                  <Box className="divider_box">
                    <Box className="divider_line" />
                    <Typography className="divider_txt">{configJSON.laberOR}</Typography>
                    <Box className="divider_line" />
                  </Box>
                  <Box className="social_login_box">
                    <img
                      className="social_login_img"
                      src={googleLogoImg}
                    />
                    <Typography className="social_login_txt">
                      {configJSON.btnTxtSocialLogin}
                    </Typography>
                  </Box>
                  <Typography className="login_desc_txt">
                    {configJSON.descriptionText}{" "}
                    <span className="login_desc_span_txt">
                      {configJSON.descriptionTextTwo}
                    </span>
                  </Typography>
                  <button className="singup_btn"
                    type="submit"
                  >{configJSON.btnTextSignUp}</button>
                </Box>
              </form>
            </MainTextinputContainer>
          </LogoIconContainer>
        </Grid>
        <Grid item xs={false} sm={6} md={6} lg={6} xl={6} >
          <Box>
            <SideImaViewContainer
              src={sideImage}
              alt="login image"
            />
          </Box>
        </Grid>
      </Grid>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const EmailAccount_Main_Container = styled(Box)({
  height: "100%"
});

const BackIcon = styled("img")({
  display: "none",
  "@media (max-width: 599px)": {
    padding: "55px 10% 0",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  "@media (max-width: 435px)": {
    padding: "10px 5% 0",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "20px"
  }
})

const LogoIconContainer = styled(Box)({
  "@media (max-width:598px)": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "0px",
    flexDirection: "column",
    padding: "10px 20px 95px 0px"
  },
});

const MainTextinputContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5%",
  padding:"25px 18% 0",
  margin:"32px",
  "& .login_box": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media (max-width:598px)": {
      width: "300px"
    }
  },

  "& .tab_box": {
    marginBottom: "17px"
  },

  "& .tab_btn_txt": {
    textAlign: "center",
    borderBottom: "1px solid #757676",
    padding: "10px 0",
    cursor: "pointer",
    width: "48%",
    fontFamily: "normal",
    fontSize: "18px",
    fontWeight: 700,
    color: "#757676"
  },

  "& .input_field_box": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px"
  },

  "& .input_field_config": {
    color: "red",
    "& .MuiOutlinedInput-root": {
      height: "55px"
    },

    "& .MuiOutlinedInput-input": {
      padding: "11px 14px 10px"
    }
  },

  "& .date_select_field": {
    width: "100%",
    height: "44px",
    color: "#757676",
    borderRadius: "7px",
    "@media (max-width:598px)": {
      width: "90px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    }
  },

  "& .date_select_fieldError": {
    width: "100px",
    height: "44px",
    color: "red",
    background: "rgba(229, 57, 53, 0.15)",
    borderRadius: "7px",
    "@media (max-width:598px)": {
      width: "90px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
      background: "rgba(229, 57, 53, 0.15)"
    }
  },

  "& .year_select_field": {
    width: "123px",
    height: "44px",
    color: "#757676",
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    }
  },

  "& .divider_box": {
    display: "flex",
    justifyContent: "space-between",
    width: "inherit",
    alignItems: "center",
    marginBottom: "26px",
    marginTop:"14px"
  },

  "& .divider_txt": {
    color: "#757676",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "-0.12px"
  },

  "& .divider_line": {
    height: "1.1px",
    width: "45%",
    backgroundColor: "#D9D9D9"
  },

  "& .social_login_box": {
    border: "1px solid #D9D9D9",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "44px",
    borderRadius: "6px",
    marginBottom: "56px",
    cursor: "pointer"
  },

  "& .social_login_img": {
    position: "absolute",
    left: "12px",
    top: "12px"
  },

  "& .social_login_txt": {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "140%",
    fontStyle: "normal"
  },

  "& .login_desc_txt": {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: " -0.1px",
    textAlign: "center",
    marginBottom: "20px"
  },

  "& .login_desc_span_txt": {
    color: "#6551EA"
  },

  "& .singup_btn": {
    height: "44px",
    borderRadius: "6px",
    backgroundColor: "#0E1518",
    color: "#FFF",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%"
  },

});

const LoginSignupBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "inherit",
  marginBottom: "32px"

})

const LoginText = styled(Typography)({
  textAlign: "center",
  padding: "10px 0",
  cursor: "pointer",
  width: "48%",
  fontSize: "17px",
  fontWeight: "bold"
})


const QualImage = styled("img")({
  width: "21%",
  objectFit: "cover",
  "@media (max-width:598px)": {
    flexDirection: "column",
    width: "35%"
  }
})

const SideImaViewContainer = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  opacity: "1",
  display: "flex",
  "@media (max-width: 599px)": {
    display: "none"
  }
})

const webStyle = {
  textInput: {
    width: "48%"
  },

  inputEmailStyle: {
    width: "100%",
    marginBottom: "20px"
  },

  passwordText: {
    color: "#757676",
    fontSize: "12px",
    marginBottom: "15px",
    marginLeft: "10px"
  },

  passwordTextError: {
    color: "red",
    fontSize: "12px",
    marginBottom: "15px",
    marginLeft: "10px"
  },

  dobStyleError: {
    color: "red",
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "5px",
    fontWeight: 500
  },

  dobStyle: {
    color: "#757676",
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "5px",
    fontWeight: 500
  },

  dateStyle: {
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "140%",
    fontSize: "14px"
  },

  dateBox: {
    backgroundColor: "rgba(229, 57, 53, 0.15)",
    color: "red"
  }
}
// Customizable Area End