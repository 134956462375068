export const hairoil = require("../assets/hairoil.png");
export const massage = require("../assets/massage.png");
export const styleHair = require("../assets/styleHair.png");
export const nails = require("../assets/nails.png");
export const hair = require("../assets/hair.png");
export const camila = require("../assets/camila.png");
export const ninoshka = require("../assets/ninoshka.png");
export const star = require("../assets/star.png");
export const quilaImage = require("../assets/quilaImage.png");
export const menu = require("../assets/menu.png");
export const whiteRoom = require("../assets/whiteRoom.png");
export const pinkRoom = require("../assets/pinkRoom.png");
export const heart = require("../assets/heart.png");
export const date = require("../assets/date.png");
export const time = require("../assets/time.png");
export const location = require("../assets/location.png");
export const search = require("../assets/search.png");
export const menuOption = require("../assets/menuOption.png");
export const home = require("../assets/home.png");
export const button = require("../assets/button.png");
export const pic = require("../assets/pic.png");
export const explore = require("../assets/explore.png");
export const save = require("../assets/save.png");
export const profile = require("../assets/profile.png");
export const setting = require("../assets/setting.png");
export const appointment = require("../assets/appointment.png");
export const notification = require("../assets/notification.png");
export const mood = require("../assets/mood.png");
export const message = require("../assets/message.png");
export const support = require("../assets/support.png");
export const settings = require("../assets/settings.png");























